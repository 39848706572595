/* eslint-disable */

export const generateKey = (key) => {
  switch (key) {
    case 'FLV':
      return 'Forced Liquidation Value';
    case 'OLV':
      return 'Orderly Liquidation Value';
    case 'FMV':
      return 'Fair Market Value';
    case 'FMVIP':
      return 'Fair Market Value - In Place';
    case 'FMVR':
      return 'Fair Market Value - Removal';
    case 'FAS':
      return 'Fair Value';
    case 'NFLV':
      return 'Net Forced Liquidation Value';
    case 'NOLV':
      return 'Net Orderly Liquidation Value';
    case 'OLVIP':
      return 'Orderly Liquidation Value - In Place';
    case 'RCN':
      return 'Replacement Cost New';
    case 'CV':
      return 'Custom Value';
    case 'FV':
      return 'FV';
    case 'year':
      return 'year';
    default:
      break;
  }
};
export const generateKeyFromName = (key) => {
  switch (key) {
    case 'Forced Liquidation Value':
      return 'FLV';
    case 'Orderly Liquidation Value':
      return 'OLV';
    case 'Fair Market Value':
      return 'FMV';
    case 'Fair Market Value - In Place':
      return 'FMVIP';
    case 'Fair Market Value - Removal':
      return 'FMVR';
    case 'Fair Value':
      return 'FAS';
    case 'Net Forced Liquidation Value':
      return 'NFLV';
    case 'Net Orderly Liquidation Value':
      return 'NOLV';
    case 'Orderly Liquidation Value - In Place':
      return 'OLVIP';
    case 'Replacement Cost New':
      return 'RCN';
    case 'Custom Value':
      return 'CV';
    case 'year':
      return 'year';
    default:
      break;
  }
};

export const generateSelected = (key) => {
  switch (key) {
    case 'FLV':
      return 'FLV';
    case 'OLV':
      return 'OLV';
    case 'FMV':
      return 'FMV';
    case 'FMVIP':
      return 'FMVIP';
    case 'FMVR':
      return 'FMVR';
    case 'FAS':
      return 'FAS';
    case 'NFLV':
      return 'NFLV';
    case 'NOLV':
      return 'NOLV';
    case 'OLVIP':
      return 'OLVIP';
    case 'RCN':
      return 'RCN';
    case 'FV':
      return 'FV';
    case 'CV':
      return 'CV';
    default:
      break;
  }
};

export const convertConditionToConditionValue = (condition) => {
  switch (condition) {
    case 'New':
      return 'N';
    case 'Excellent':
      return 'E';
    case 'Very Good':
      return 'VG';
    case 'Good':
      return 'G';
    case 'Fair':
      return 'F';
    case 'Poor':
      return 'P';
    case 'NA':
      return 'NA';
    case 'Scrap':
      return 'X';
    case 'Salvage':
      return 'S';
    default:
      return condition;
  }
};
export const convertConditionValueToCondition = (condition) => {
  switch (condition) {
    case 'N':
      return 'New';
    case 'E':
      return 'Excellent';
    case 'VG':
      return 'Very Good';
    case 'G':
      return 'Good';
    case 'F':
      return 'Fair';
    case 'P':
      return 'Poor';
    case 'NA':
      return 'NA';
    case 'X':
      return 'Scrap';
    case 'S':
      return 'Salvage';
    default:
      return condition;
  }
};
export const convertClassValueToClass = (key) => {
  switch (key) {
    case 'P':
      return 'Production Equipment';
    case 'S':
      return 'Production Support Equipment';
    case 'M':
      return 'Mobile Equipment';
    case 'T':
      return 'Transportation';
    case 'G':
      return 'General Plant Support Equipment';
    case 'F':
      return 'Office Furniture, Fixtures & Equipment';
    default:
      return '';
  }
};

export const reOrderArray = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export const myDebounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const createObjectFromArray = (dataArray) => {
  const result = {};
  dataArray.forEach((item) => {
    result[item.value_code] = ''; // Set the key as value_code and value as an empty string
  });
  return result;
};

export const calculateNavigation = (assetData, currentAssetId) => {
  if (isNaN(currentAssetId)) return {};
  const currentIndex = assetData.findIndex(
    (asset) => asset.type_id === currentAssetId
  );

  const prev = currentIndex > 0 ? assetData[currentIndex - 1].type_id : null;

  const next =
    currentIndex < assetData.length - 1
      ? assetData[currentIndex + 1].type_id
      : null;

  return { prev, next };
};

export const generatePriceObject = (priceObject, values) => {
  let priceKeys = priceObject.split('#'),
    priceValues = values.split('#'),
    calculatedObject = {};
  console.log('🚀 ~ generatePriceObject ~ priceKeys:', priceKeys, priceValues);
  priceKeys.forEach((element, index) => {
    calculatedObject[element] = priceValues[index];
  });
  return calculatedObject;
};
export const generatePriceObjectWithNames = (priceObject, values) => {
  let priceKeys = priceObject.split('#'),
    priceValues = values.split('#'),
    calculatedObject = {};
  console.log('🚀 ~ generatePriceObject ~ priceKeys:', priceKeys, priceValues);
  priceKeys.forEach((element, index) => {
    const key = generateKey(element);
    calculatedObject[key] = priceValues[index];
  });
  return calculatedObject;
};

export const generatePriceObjectFromPriceVal = (priceObject) => {
  const computedPriceVal = {};
  Object.keys(priceObject).forEach((key) => {
    // generateKeyFromName(key);
    // computedPriceVal[key] = priceObject[key];
    computedPriceVal[generateKeyFromName(key)] = priceObject[key];
    // console.log(key, generateKeyFromName(key), priceObject[key], 'yoi');
  });
  console.log(
    '🚀 ~ generatePriceObjectFromPriceVal ~ computedPriceVal:',
    computedPriceVal
  );
  return computedPriceVal;
};
export const storageCleanUp = () => {
  localStorage.removeItem('dropTo');
  localStorage.removeItem('insertPosition');
  localStorage.removeItem('editMode');
  // localStorage.removeItem('')
  // localStorage.removeItem('')
};

export const generateTypeAheadValues = (data) => {
  return 'hello yoi';
};

export const capitalizeString = (inputString) => {
  return inputString
    .split(' ')
    .map((i) => `${i.charAt(0).toUpperCase()} ${i.slice(1)}`)
    .join(' ');
};

export const resetAssetFormData = (data) => {
  delete data.appraiser_note;
  delete data.asset_no;
  delete data.asset_type_id;
  delete data.capacity;
  delete data.cost;
  delete data.description;
  delete data.flagged;
  delete data.is_not_found;
  delete data.make;
  delete data.make_id;
  delete data.type_id;
  data.priceVal = {};
  delete data.serial_number;
  delete data.special_notes;
  delete data.type;
  delete data.year;
  delete data.model;
  delete data.mileage;
  delete data.area_asset_order;
  delete data.asset_id;
  delete data.model_label_id;
  delete data.serial_label;
  delete data.area_order;
  data.asset_class_id = 0;
  data.model_label_id = 1;
  data.condition = 'Good';
  data.quantity_type = 'number';
  data.serial_label_id = 1;
  data.mileage_type = 'mileage';
  return data;
};
