/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';

import { kaReducer, Table } from 'ka-table';
import {
  SortingMode,
  EditingMode,
  // InsertRowPosition,
  // ActionType,
} from 'ka-table/enums';
// import FlagSharpIcon from '@mui/icons-material/FlagSharp';
import { Edit, Flag as FlagIcon } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  fetchAssetList,
  fetchAssetDetails,
  getAssetList,
  postFlagged,
  updateAssetClass,
  getAssetInEdit,
  // updateAssetClass,
} from '../../services/assetListService';
import { columns } from '../../utils/Table';
import { reorderReducer } from '../../reducers';
import DataGridStyled from './DataGridStyled.style';
import { ColumnToggle } from './Column';
import { decode as base64Decode } from 'base-64';
import { SelectionCell, PageSize, SelectionLine } from '../Tables/CustomTable/components';
import { SelectionHeader } from '../Tables/CustomTable/components';
// import { SwitchIcon } from '../Tables/CustomTable/components';
import { kaPropsUtils } from 'ka-table/utils';
import { CustomMenu } from '../Tables/CustomTable/components';
import { useHistory } from 'react-router-dom';
import InsertAreaDialog from '../Tables/CustomTable/components/InsertAreaDialog';
import BlockMoveDialog from '../Tables/CustomTable/components/BlockMoveDialog';
import ReassignDialog from '../Tables/CustomTable/components/ReassignDialog';
import AlertMessage from '../AlertMessage';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import {
  openAllEditors,
  // deleteRow,
  updateGroupsExpanded,
  // updateFilterRowValue,
} from 'ka-table/actionCreators';
import {
  // getAssetListDataAction,
  // postNewAreaAction,
  assetDropAction,
  // postFlaggedAction,
  // postNewLineAction,
  deleteAssetAction,
  // reassignAction,
  copyAssetBelowAction,
  blockMoveAssetAction,
  getAssetClassAction,
  postAssetClassAction,
  getConditions,
  // postRenameArea,
} from '../../redux/assets/AssetsActions';
import { assetsListActions } from '../../redux/assets/assets';
import InsertNewLineDialog from '../Tables/CustomTable/components/InsertNewLineDialog';
import { ParentMenu } from '../Tables/CustomTable/components';
import SelectionDropdown from '../SelectionDropdown/selectionDropdown';
import { Box, IconButton, Typography } from '@mui/material';
import {
  generateKey,
  convertConditionToConditionValue,
  convertConditionValueToCondition,
  convertClassValueToClass,
  storageCleanUp,
} from '../../utils/Utils';
import ConditionDropdown from '../ConditionDropdown';
import EditPopup from '../Tables/CustomTable/components/EditPopup/editPopup';
import { column } from '../../utils/Column';
import { lineListActions } from '../../redux/lines/line';
import ConfirmationAlert from '../ConfirmationAlert';
import { getLocationsDataAction } from '../../redux/home/HomeActions';
import {
  getMakeCapacityAction,
  getAssetDetailsAction,
} from '../../redux/lines/LineActions';
import { getModelByMakeAction } from '../../redux/assets/AssetsActions';
import { condition } from '../../constants/constant';
// import SelectionLine from '../Tables/CustomTable/components/SelectionLine';
// import { CustomTextBox } from '../Inputs';

const MODEL_DATA = localStorage.getItem('make_model');
const PAGE_SIZE = localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50
const CURRENT_PAGE = localStorage.getItem('current_active_page') ? +localStorage.getItem('current_active_page') :null

// const PAGE_SIZE = localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50;

const scrollToHighlightedRow = (typeId, rows) => {
  const currentRowIndex = rows.findIndex(({ type_id }) => +type_id === +typeId) + 1;
  const current_page_size = localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50
  const divisionResult = currentRowIndex / +current_page_size;
  // 1 - multiple cases need to be handled thus the if and else if game
  // 2 - a little time till we move the pages thus the timeout to scroll
  let currentPageByRowIndex = 0
  if(divisionResult % 1 >= 0.99) {
    currentPageByRowIndex = Math.ceil(divisionResult)
  } else if (divisionResult % 1 == 0) {
    currentPageByRowIndex = Math.ceil(divisionResult) -1
  } else {
    currentPageByRowIndex = Math.floor(divisionResult)
  }
  if(currentPageByRowIndex >= 0) {
    setTimeout(() => {
      const highlightedRow = document.getElementById('highlighted_row'); // ! I know it's pretty ugly and unconventional to use DOm manipulation in react but the case
      if (highlightedRow) {
        highlightedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 1500);
    return currentPageByRowIndex;
  }

};
const DataGrid = ({
  refs,
  asset_ids,
  config,
  columnConfig,
  // setcolumnConfig,
  handleSidebar,
  // locationId,
  // searchText,
  columnSetting,
  handleSelect,
  showIcon,
  inputdata,
  isGlobalSearch,
  updateParentGlobalSearch,
  filterAssetInDataGrid,
  // search,
  appraiserData,
  handleAssetCount,
}) => {
  const history = useHistory();
  const {
    dataKeys: { attributes, orders },
  } = config;
  const {
    dataKeys: { attribute, order },
  } = columnConfig;

  const tablePropsInit = {
    columns: orders.map((key) => {
      return {
        ...columns[key],
        visible: attributes[key].visibility,
      };
    }),
    data: [],
    height: '75vh',
    editingMode: EditingMode.Cell,
    rowKeyField: 'type_id',
    rowReordering: true,
    groups: [
      {
        columnKey: 'location_address',
        key: 'location_id',
      },
      {
        columnKey: 'area',
        key: 'area_order',
      },
      // {
      //   columnKey: 'parent_line',
      // },
    ],
    sortingMode: SortingMode.Single,
    paging: {
      enabled: true,
      // pageIndex: 0,
      pageIndex: CURRENT_PAGE ? CURRENT_PAGE : 0,
      pageSize: +PAGE_SIZE,
      pageSizes: [50, 100, 150, 200, 300],
    },
  };
  const columnPropsInit = {
    columns: order.map((key) => {
      return {
        ...column[key],
        visible: attribute[key].visibility,
      };
    }),
  };
  const [columnChooserProps, changeColumnChooserProps] =
  useState(tablePropsInit);
  console.log("🚀 ~ changeColumnChooserProps:", columnChooserProps)
  const [columnProps, changeColumnProps] = useState(columnPropsInit);
  const [rows, setRows] = useState([]);
  // const [lines, setLines] = useState();
  // const [fetchAssetDetailsRequest, setFetchAssetDetailsRequest] =
  //   useState(false);
  const [valueCode, setValueCode] = useState([]);
  // const [assetDetails, setAssetDetails] = useState();
  const [visible, setVisible] = useState(false);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [rowData, setRowData] = useState();
  const [parentRowData, setParentRowData] = useState();
  const [selectedEditableCell, setSelectedEditableCell] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [item, setItem] = useState();
  const [addArea, setAddArea] = useState();
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [assetData, setAssetData] = useState([]);
  const [flagId, setFlagId] = useState();
  const assetsState = useSelector((state) => state.assets);
  const [open, setOpen] = useState(false);
  // const [draggedRow, setDraggedRow] = useState(null);
  const [assetResponse, setAssetResponse] = useState([]);
  const [toggleClass, setToggleClass] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [areaEdit, setAreaEdit] = useState(false);
  const [areaName, setAreaName] = useState(null);
  const [updateArea, setUpdateArea] = useState(null);
  // const [searchValue, setSearchValue] = useState();
  const [toggleCondition, setToggleCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRowIdCondition, setCurrentRowIdCondition] = useState(null);
  const [locationValue, setLocationValue] = useState();
  const [selectItems, setSelectItems] = useState([]);
  // we need to set a loading state since we are directly calling the service, this needs to be managed somehow
  const [getAssetListData, setGetAssetListData] = useState(false);
  const [lastGetAssetData, setLastGetAssetData] = useState(true); // ! will remove as part of new sprint when we cleanup redux - set it back to false might cause new issues now
  // const tableRef = useRef(null); // Create a ref for Ka-Table instance
  const { doFetchAssetList } = useSelector((state) => state.assets);
  // const adminState = useSelector((state) => state.admin);
  const lineState = useSelector((state) => state.line);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isEnable, setEnable] = useState(false);
  const [value, setValue] = useState([]);
  const [inputLineValues, setInputLineValues] = useState({});
  const assetTypes = assetData?.assetTypeList;
  const makeList =
    lineState?.makeCapacityList?.makeCapacityList?.data?.makeList;
  const filteredAssetTypes =
    assetTypes?.length > 0 &&
      assetTypes[0]?.asset_type === 'Select or search asset type'
      ? assetTypes?.slice(1)
      : assetTypes;

  const VISIBLE_COLUMNS = localStorage.getItem('visibleColumns');
  // const ITEMS_PER_PAGE = localStorage.getItem('itemsPerPage');
    const filterAppraiserId = localStorage.getItem('selectedAppraiser')
  const filterArea = localStorage.getItem('selectedArea')
  const selectedLocation = localStorage.getItem('location_id_home')
  const refPayload = {
    pageNumber: 3, // not needed but sending just till we update the api post prod
    pageSize: 60, // not needed but sending just till we update the api post prod
    sortCol: 'area_order',
    sortOrder: 'asc',
    action: 'getAssetInEdit',
    isAudio: false, // not needed but sending just till we update the api post prod
    NForDeleted: false, // not needed but sending just till we update the api post prod
    showOnlyAsset: false, // not needed but sending just till we update the api post prod
    hideCategorized: false, // not needed but sending just till we update the api post prod
    appraiser: filterAppraiserId, // not needed but sending just till we update the api post prod
    make: null, // not needed but sending just till we update the api post prod
    model: null, // not needed but sending just till we update the api post prod
    capacity: null, // not needed but sending just till we update the api post prod
    area: filterArea, // not needed but sending just till we update the api post prod
    global_search: null, // not needed but sending just till we update the api post prod
    location: selectedLocation, // not needed but sending just till we update the api post prod
    currentPageNumber: 3, // not needed but sending just till we update the api post prod
  };

  useEffect(() => {
    storageCleanUp()
  }, [])
  useEffect(() => {
    if (doFetchAssetList || getAssetListData || isGlobalSearch || isFetchingList) setLastGetAssetData(false)
  }, [doFetchAssetList, getAssetListData, isGlobalSearch, isFetchingList]) // - needs absolute cleanup because it is causing too many api calls
  useEffect(() => {
    if (!VISIBLE_COLUMNS) {
      localStorage.setItem('visibleColumns', JSON.stringify(columnProps));
    }
  }, [columnProps, VISIBLE_COLUMNS]);

  const filterLocation = new URLSearchParams(location.search).get('location');

  const dispatchAction = useDispatch();
  // let typeId;
  let userId = localStorage.getItem('userId');
  let projectIDCrm = localStorage.getItem('project_id_crm');
  let decodedProjectId = base64Decode(projectIDCrm); // This is a Base64 encoded string

  const generateUpdatedTableProps = (computedOrders) => {
    const PAGE_SIZE = localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50
    const tablePropsInit = {
      columns: computedOrders.map((key) => {
        return {
          ...columns[key],
          visible: VISIBLE_COLUMNS
            ? JSON.parse(VISIBLE_COLUMNS)?.columns?.find((i) => i.key == key)
              ?.visible
            : attributes[key].visibility,
        };
      }),
      data: [],
      height: '75vh',
      editingMode: EditingMode.Cell,
      rowKeyField: 'type_id',
      rowReordering: true,
      groups: [
        {
          columnKey: 'location_address',
          key: 'location_id',
        },
        {
          columnKey: 'area',
          key: 'area_order',
        },
        // {
        //   columnKey: 'parent_line',
        // },
      ],
      sortingMode: SortingMode.Single,
      paging: {
        enabled: true,
        pageIndex: 0,
        pageSize: +PAGE_SIZE,
        pageSizes: [50, 100, 150, 200, 300],
      },
    };
    return tablePropsInit;
  };

  useEffect(() => {
    let locationGetLastAsset = {
      getLastAsset: true,
      project_id_crm: decodedProjectId,
      flag: 'addAsset',
    };
    let makeCapacityData = {
      type: 'getMakeCapacityList',
      project_id_crm: decodedProjectId,
    };
    dispatchAction(getLocationsDataAction(locationGetLastAsset));
    dispatchAction(getMakeCapacityAction(makeCapacityData));
    if (
      !assetsState?.getModelByMake.loading &&
      assetsState?.getModelByMake?.getModelByMake.length === 0 &&
      !MODEL_DATA
    ) {
      dispatchAction(getModelByMakeAction());
    }
  }, [dispatchAction]);
  // handle with local storage location id

      useEffect(() => {
      if(columnChooserProps?.editableCells?.length) {
        const elems = document.getElementsByClassName('ka-input') // ! I know it's pretty ugly and unconventional to use
        //! DOM manipulation in react but the case just didn't work no matter what I tried with our implementation. Something was done wrong and I cannot find it in this 300+ line of bs 
        if(!elems.length)return
        elems[0].select()
      }
    }, [columnChooserProps?.editableCells])

  useEffect(() => {
    let assetData = {
      location_id: inputdata?.location ?? '',
      type: 'line',
      project_id_crm: decodedProjectId,
    };
    dispatchAction(getAssetDetailsAction(assetData));
  }, []);

  useEffect(() => {
    setAssetData(lineState?.assetDetails?.assetDetails?.data);
  }, [lineState?.assetDetails?.assetDetails]);

  // test this again, quite confusing rn
  useEffect(() => {
    if (columnChooserProps?.selectedRows?.length > 0) {
      const selectRow = rows?.reduce((accumulator, item) => {
        if (columnChooserProps?.selectedRows?.includes(item?.type_id)) {
          accumulator.push(item);
        }
        return accumulator;
      }, []);

      setSelectItems(selectRow);
    }
  }, [columnChooserProps?.selectedRows, rows]);

  useEffect(() => {
    dispatchAction(getAssetClassAction());
    dispatchAction(getConditions());
  }, [dispatchAction]);

  // etracted duplicated logic into a function
  // const updateCellValue = (prevState, action, type) => {
  //   const { columnKey, value, rowKeyValue } = action;
  //   const key = generateKey(columnKey);
  //   const modified_by = Number(userId);

  //   const obj = {
  //     key,
  //     key_value: value,
  //     modified_by,
  //     type: 'asset',
  //     type_id: rowKeyValue,
  //     update_type: 'value',
  //   };

  //   if (type === 'values') {
  //     dispatchAction(postAssetClassAction(obj, 'values'));
  //   }
  //   changeColumnChooserProps({
  //     ...columnChooserProps,
  //     // data: filteredRefAssets,
  //   });
  // }

  useEffect(() => {
    changeColumnProps(JSON.parse(VISIBLE_COLUMNS));
  }, [VISIBLE_COLUMNS]);

  // for handling which column is visible after action from sidebar
  useEffect(() => {
    columnProps?.columns?.map((item) => {
      columnChooserProps?.columns.map((data) => {
        if (item?.key === data.key) {
          data.visible = item.visible;
        }
      });
      changeColumnChooserProps({ ...columnChooserProps });
    });
  }, [columnProps]);

  const dispatch = (action) => {
    changeColumnProps((prevState) => {
      switch (action.type) {
        case 'ReorderRows':
          return reorderReducer(prevState, action);
        case 'UpdateCellValue':
          return kaReducer(prevState, action);
        default:
          return kaReducer(prevState, action);
      }
    });
  };

  // const CheckValue = (action, prevState) => {
  //   const previousValue = prevState.data.find(
  //     ({ type_id }) => +type_id === action.rowKeyValue
  //   );
  //   let inputValues = action.value.trim();
  //   let flag;
  //   let digitRegex = /^[0-9]+$/;
  //   const allowedValues = ['no value', 'leased', 'not valued'];
  //   const lowerInputValues = inputValues.toLowerCase().trim();
  //   if (
  //     allowedValues.includes(lowerInputValues) ||
  //     digitRegex.test(inputValues) ||
  //     inputValues === ''
  //   ) {
  //     flag = true;
  //   } else {
  //     flag = false;
  //   }

  //   if (flag) {
  //     return inputValues;
  //   } else {
  //     return previousValue[action.columnKey];
  //   }
  // };
  const kaTableDispatch = (action) => {
    changeColumnChooserProps((prevState) => {
      switch (action.type) {
        case 'ReorderRows':
          return reorderReducer(prevState, action);
        case 'UpdateCellValue':
          const key = generateKey(action.columnKey);
          const currentActiveRow = prevState.data.find(
            ({ type_id }) => +type_id === action.rowKeyValue
          )
          let obj = {
            key,
            key_value: action?.value,
            // key === 'year' ? action.value : CheckValue(action, prevState),
            modified_by: Number(userId),
            type: currentActiveRow.type,
            type_id: action.rowKeyValue,
            update_type: key == 'year' ? 'field' : 'value',
          };
          // ! we only proceed if the current cell is one of the price values (flv, olv, fmv, etc...)
          if (valueCode?.includes(action.columnKey)) {
            // ! if the value in empty during edit and we move to another cell or out of focus of this cell
            if(obj.key_value == 0 && currentActiveRow.type == 'line') {
              // return
              let allRows = [...prevState?.data]
              action.value = "" // we reset the value from the action itself, thus updating the table in the process
              obj.key_value = "" // we just won't make the api call anymore then.
              const allChildRows = allRows.filter(({ parent_line }) => +parent_line === +obj.type_id)
                let newLinePriceValue = 0
                allChildRows.forEach((childRow) => {
                  if (parseInt(childRow[action.columnKey]) > 0) {
                    newLinePriceValue += parseInt(childRow[action.columnKey])
                  }
                })
                const currentRowFromList = allRows.find(({ type_id }) => +type_id === +action.rowKeyValue)
                currentRowFromList[action.columnKey] = newLinePriceValue
                action.value = newLinePriceValue
                const valueType = `${action.columnKey}-color`
                currentRowFromList[valueType] = '0'
                if (currentRowFromList.overriden) {
                  let overriddenValues = currentRowFromList.overriden.split('#');
                  let index = valueCode?.indexOf(action.columnKey);

                  if (index !== -1) {
                    overriddenValues[index] = '0';
                  }

                  currentRowFromList.overriden = overriddenValues.join('#');
                }
            } else if (
              (obj.key_value.toLowerCase() != 'no value' &&
                obj.key_value.toLowerCase() != 'not valued' &&
                obj.key_value.toLowerCase() != 'leased' &&
                isNaN(obj.key_value)) ||
              !obj.key_value
            ) {
              action.value = "" // we reset the value from the action itself, thus updating the table in the process
              obj.key_value = "" // we just won't make the api call anymore then.
              let allRows = [...prevState?.data]
              if (currentActiveRow.type == 'line') {
                // ! if current row is a line, we first reset it's value to a sum of all the assets in that line and set the overriden 
                //! field to 0 for that price value. eg: 0#0#0 is olv#flv#fmv, we set whichever one we updated to 0 so the color is set to black
                const allChildRows = allRows.filter(({ parent_line }) => +parent_line === +obj.type_id)
                let newLinePriceValue = 0
                allChildRows.forEach((childRow) => {
                  if (parseInt(childRow[action.columnKey]) > 0) {
                    newLinePriceValue += parseInt(childRow[action.columnKey])
                  }
                })
                const currentRowFromList = allRows.find(({ type_id }) => +type_id === +action.rowKeyValue)
                currentRowFromList[action.columnKey] = newLinePriceValue
                action.value = newLinePriceValue
                const valueType = `${action.columnKey}-color`
                currentRowFromList[valueType] = '0'
                if (currentRowFromList.overriden) {
                  let overriddenValues = currentRowFromList.overriden.split('#');
                  let index = valueCode?.indexOf(action.columnKey);

                  if (index !== -1) {
                    overriddenValues[index] = '0';
                  }

                  currentRowFromList.overriden = overriddenValues.join('#');
                }
              }
              if (currentActiveRow.type == 'asset') {
                // ! if the row type is asset, we remove it's value and set it to an empty string or null in step 1
                allRows.find(({ type_id }) => +type_id === +action.rowKeyValue)[action.columnKey] = null // ! we no longer set a value because we reset it if the value is not valid and in case of an asset that means no value at all
                // ! if the current price value we are updating has not be overriden in the line of this asset, we update the line price value for that key.
                if (+currentActiveRow.parent_line > 0 && allRows.find(({ type_id }) => +type_id === +currentActiveRow.parent_line).overriden?.split('#')[valueCode?.indexOf(action.columnKey)] != '1') {
                  const allChildRows = allRows.filter(({ parent_line }) => +parent_line === +currentActiveRow.parent_line)
                  let newLinePriceValue = 0
                  allChildRows?.forEach((childRow) => {
                    if (parseInt(childRow[action.columnKey]) > 0) {
                      newLinePriceValue += parseInt(childRow[action.columnKey])
                    }
                  })
                  allRows.find(({ type_id }) => +type_id === +currentActiveRow.parent_line)[action.columnKey] = newLinePriceValue
                }
              }
              setRows(allRows)
            } else {
              if(action.value == 0) action.value = ''
              let allRows = [...prevState?.data]
              const currentRows = [...prevState?.data] //! just a placeholder in case things go wrong and we have to revert
              if (currentActiveRow.type == 'line') {
                // ! if we are updating a line price value we update it's value and set overriden to 1 for that price key and the color to 1 as well.
                let currentRowFromList = allRows.find(({ type_id }) => Number(type_id) === Number(action.rowKeyValue));
                if (currentRowFromList) {
                  currentRowFromList[action.columnKey] = action.value;
                  if (currentRowFromList.overriden) {
                    let overriddenValues = currentRowFromList.overriden.split('#');
                    let index = valueCode?.indexOf(action.columnKey);
                    if (index !== -1) {
                      overriddenValues[index] = '1';
                      const valueType = `${action.columnKey}-color`
                      currentRowFromList[valueType] = '1'
                      // if (obj.key_value == 0) {
                      //   currentRowFromList[valueType] = '0'
                      // } else {
                        //   currentRowFromList[valueType] = '1'
                      // }
                    }
                    currentRowFromList.overriden = overriddenValues.join('#');
                  }
                  allRows = allRows.map(row => row.type_id === currentRowFromList.type_id ? currentRowFromList : row);
                }

              }
              if (currentActiveRow.type == 'asset') {
                allRows.find(({ type_id }) => +type_id === +action.rowKeyValue)[action.columnKey] = action.value
                //! if the asset has a parent line and the line value has not been set manually we update it.
                if (+currentActiveRow.parent_line > 0 && allRows.find(({ type_id }) => +type_id === +currentActiveRow.parent_line).overriden?.split('#')[valueCode?.indexOf(action.columnKey)] != '1') {
                  const allChildRows = allRows.filter(({ parent_line }) => +parent_line === +currentActiveRow.parent_line)
                  let newLinePriceValue = 0
                  allChildRows?.forEach((childRow) => {
                    if (parseInt(childRow[action.columnKey]) > 0) {
                      newLinePriceValue += parseInt(childRow[action.columnKey])
                    }
                  })
                  allRows.find(({ type_id }) => +type_id === +currentActiveRow.parent_line)[action.columnKey] = newLinePriceValue
                }
              }
              setRows(allRows)
            }
            updateAssetClass(obj).then().catch(() => {
              setRows(currentRows)
            })
          } else if (key === 'year') {
            let allRows = [...rows]
            for (const row of allRows) {
              if (row.type_id === currentActiveRow.type_id) {
                row.year = obj.key_value;
              }
            }
            let currentRows = [...rows];
            const currentColumnState = {...columnChooserProps}
              setRows(allRows);
              changeColumnChooserProps({...columnChooserProps, data: allRows})
              updateAssetClass(obj).then(() => {

              }).catch(() => {
                setMessage('Something wen\'t wrong')
                setStatus('warning')
                setRows(currentRows);
                changeColumnChooserProps(currentColumnState)
              })
          } else {
            // todo - will remove this altogether because of the absurd complexity it causes
            dispatchAction(
              postAssetClassAction(obj, key == 'year' ? 'year' : 'values')
            );
          }
          return kaReducer(prevState, action);
        default:
          return kaReducer(prevState, action);
      }
    });
  };

  const cellSkeleton = (content, args, index) => {
    return (
      <td key={index} className="ka-group-cell" {...args}>
        <div className="ka-group-cell-content table_inner_text">
          <div className="ka-group-text">{content}</div>
        </div>
      </td>
    );
  };

  const generateCell = (key, content, args, index) => {
    if (key === 'quantity') {
      content = 'quantityType';
    }

    return cellSkeleton(content, args, index);
  };

  // ! new data row line function
  const newDataRowLine = (lines, rows, currentLine) => {
    if (!lines[currentLine]) return;

    const { value_short_name, value, overriden } = lines[currentLine][0];
    const valueArray = value.split('#');
    const values = value_short_name.split('#').reduce((acc, val, i) => {
      acc[val] = valueArray[i] !== '' ? valueArray[i] : '';
      return acc;
    }, {});
    const updatedArray = overriden.split('#');
    const overridenValues = value_short_name
      .split('#')
      .reduce((acc, val, index) => {
        acc[`${val}-color`] = updatedArray[index];
        return acc;
      }, {});
    const lineAssets = rows?.filter(
      (row) => row.parent_line === parseInt(currentLine)
    );
    const lineValues = lineAssets.reduce((acc, row) => {
      valueCode?.forEach((locationPriceValue) => {
        acc[locationPriceValue] =
          (acc[locationPriceValue] || 0) +
          (isNaN(row[locationPriceValue]) ||
            !Number.isInteger(row[locationPriceValue])
            ? 0
            : row[locationPriceValue]);
      });
      return acc;
    }, {});

    const currentLineDetails = {
      ...lines[currentLine][0],
      ...lineValues,
      ...values,
      ...overridenValues,
    };

    return currentLineDetails;
  };

  const generateObject = (attributes, currentLineDetails, valueCode) => {
    const generatedObject = {};

    orders.forEach((column) => {
      if (attributes[column].visibility) {
        if (attributes[column].model?.includes('quantity')) {
          generatedObject[column] =
            currentLineDetails[attributes[column].secondaryKey];
        } else if (attributes[column].model?.includes('line')) {
          generatedObject[column] =
            currentLineDetails[column] ||
            currentLineDetails[attributes[column].secondaryKey];
        } else if (attributes[column].toggable) {
          generatedObject[column] = null; // Set to null for toggables (customize as needed)
        }
      }
    });

    return generatedObject;
  };

  const DataRowLine = (props) => {
    const { groupKey, groupIndex, lines, rows, attributes, valueCode, orders } =
      props;

    if (
      groupKey[groupIndex] === '0' ||
      !lines ||
      !lines[groupKey[groupIndex]]
    ) {
      return null;
    }

    const currentLine = groupKey[groupIndex];
    const currentLineDetail = lines[currentLine][0];
    const { value_short_name, value } = currentLineDetail;
    const valueArray = value.split('#');
    const values = value_short_name.split('#').reduce((acc, val, i) => {
      acc[val] = valueArray[i] !== '' ? valueArray[i] : '';
      return acc;
    }, {});

    const lineAssets = rows?.filter(
      (row) => row.parent_line === parseInt(currentLine)
    );
    const lineValues = lineAssets.reduce((acc, row) => {
      valueCode?.forEach((locationPriceValue) => {
        acc[locationPriceValue] =
          isNaN(row[locationPriceValue]) ||
            !Number.isInteger(row[locationPriceValue])
            ? 0
            : acc[locationPriceValue] + row[locationPriceValue];
      });
      return acc;
    }, {});

    const currentLineDetails = {
      ...currentLineDetail,
      ...lineValues,
      ...values,
    };

    const generatedObject = generateObject(
      attributes,
      currentLineDetails,
      valueCode
    );

    return (
      <>
        <td className="ka-empty-cell" />
        <td className="ka-empty-cell" />
        <td className="ka-empty-cell" />
        <td className="ka-group-cell">
          <div className="ka-group-cell-content">
            <div className="ka-group-text">{currentLine}</div>
          </div>
        </td>
        {orders.map((column, index) => {
          if (
            attributes[column].visibility &&
            attributes[column].model?.includes('quantity')
          ) {
            return generateCell(
              column,
              currentLineDetails[attributes[column].secondaryKey],
              {
                title: column,
                ...(valueCode?.includes(column) &&
                  values[column] !== '' && { style: { color: 'red' } }),
              },
              index
            );
          } else if (
            attributes[column].visibility &&
            attributes[column].model?.includes('line')
          ) {
            return generateCell(
              column,
              currentLineDetails[column] ||
              currentLineDetails[attributes[column].secondaryKey],
              {
                title: column,
                ...(valueCode?.includes(column) &&
                  values[column] !== '' && { style: { color: 'red' } }),
              },
              index
            );
          } else if (
            attributes[column].visibility &&
            attributes[column].toggable
          ) {
            return <td key={index} className="ka-empty-cell" />;
          }
        })}
      </>
    );
  };

  // useEffect(() => { // ! honest to God makes no sense at this point but nothing works unless I do it this way
  //   if(isFetchingList || doFetchAssetList) return // ! we prevent the getAssetListData state from being updated while we are still fetching the data
  //   setGetAssetListData(true);
  // }, [
  //   inputdata.location,
  //   inputdata.area,
  //   inputdata.appraiser,
  //   inputdata.isAudio,
  //   inputdata.delete,
  //   inputdata.show_assets,
  //   inputdata.hide_assets,
  //   // search,
  // ]);
  const handleCount = (count) => {
    handleAssetCount(count);
  };

  const handleResetSearch = () => {
    updateParentGlobalSearch(false);
  };

  // for remove two rows highlighted at the same time


  const extractLineAndAsset = (inputObject) => {
    const lineList = [];
    const assetList = [];

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        const obj = inputObject[key];

        if (obj.hasOwnProperty('type')) {
          if (obj.type === 'line') {
            lineList.push(obj);
          } else if (obj.type === 'asset') {
            assetList.push(obj);
          }
        }
      }
    }

    return {
      lineList,
      assetList,
    };
  };
  
  const updateLocationOrder = (assets) => {
    const newAssetData = []
    // ! we now set a new field in the assets called locationOrder which we take from the locationData based on the indexof the location.
    //!  We then use this locationOrder field to order the assets rather than the location_id thus adding right ref to each asset/line
    assets.forEach((item, index) => {
      newAssetData[index] = {...item, locationOrder: assetsState?.getAssetListData?.getLocationtData.findIndex(({location_id}) => +location_id === +item?.location_id)}
    })
    return newAssetData

  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          (getAssetListData || doFetchAssetList || (doFetchAssetList && isGlobalSearch) || lastGetAssetData) &&
          !isFetchingList
        ) {
          setIsFetchingList(true);
          const locationIdFromLs = localStorage.getItem('location_id_home') // !to filter out assets based on home location id
          const selectedArea = localStorage.getItem('selectedArea') // !to filter out assets based on area
          const selectedAppraiser = localStorage.getItem('selectedAppraiser') // !to filter out assets based on appraiser
          const searchString = localStorage.getItem('global_search_string') // !to filter out assets based on search value
          const res = await fetchAssetList({ ...inputdata, location: locationIdFromLs, area: selectedArea, appraiser: selectedAppraiser, search: searchString });
          setAssetResponse(res);
          handleCount(res?.totalAssetCount);
          let { line, asset, min_ref_no: startingRefIndex } = res;
          if (!line && typeof asset === 'object' && !Array.isArray(asset)) {
            // this is a search thing, no energy to explain rn
            let { assetList, lineList } = extractLineAndAsset(asset);
            asset = assetList;
          }
          if(!assetsState?.getAssetListData?.getLocationtData?.length) {
            return setIsFetchingList(false);
          } 
          asset = updateLocationOrder(asset)
          asset.sort(
            (a, b) =>
              // a.location_id - b.location_id ||
            a.locationOrder - b.locationOrder ||
            a.area_order - b.area_order ||
            // a.asset_id - b.asset_id || // for correct sequence when we create assets
            a.asset_area_order - b.asset_area_order
          );
          const refAssets = asset.map((asset, index) => {
            if (line && asset.parent_line && line.hasOwnProperty(asset.parent_line)) {
              if (
                line[asset.parent_line][0].area === '' ||
                !line[asset.parent_line][0].area_order
              ) {
                const objectToUpdate = line[asset.parent_line][0];
                Object.assign(objectToUpdate, {
                  area_order: asset.area_order,
                  // area_asset_order: asset.area_asset_order ,
                  // special_notes: asset.special_notes,
                  // mileage_type: asset.mileage_type,
                  // mileage: asset.mileage,
                  // serial_label_id: asset.serial_label_id,
                  // asset_type_id: asset.asset_type_id,
                  // model_label: asset.model_label,
                  // serial_label: asset.serial_label,
                  // appraiser: asset.appraiser,
                  // user_id: asset.user_id,
                  // condition_value: asset.condition_value,
                  ///
                  location_id: asset.location_id,
                  area: asset.area,
                  serial_number: asset.serial_number,
                  project_id: asset.project_id,
                  location_address: asset.location_address,
                  condition_value: convertConditionToConditionValue(
                    objectToUpdate.condition
                  ),
                  capacity: objectToUpdate.line_name,
                });
                line[asset.parent_line][0] = objectToUpdate;
              }
            } else if (asset.type === 'line') {
              asset.capacity = asset.line_name
              // if(asset.assets.length) {
              //   const firstAssetInLine = asset.assets[0]
              //   const currentLine = {...asset}
              //   Object.assign(currentLine, {
              //     area_order: firstAssetInLine.area_order,
              //     location_id: firstAssetInLine.location_id,
              //     area: firstAssetInLine.area,
              //     serial_number: firstAssetInLine.serial_number,
              //     project_id: firstAssetInLine.project_id,
              //     location_address: firstAssetInLine.location_address,
              //     condition_value: convertConditionToConditionValue(
              //       currentLine.condition
              //     ),
              //     capacity: currentLine.line_name,
              //   })
              //   refAssets[index] = currentLine
              // }
              console.log(asset)
            }
            const { value_short_name, value } = asset;
            const valueArray = value.split('#');
            const values = value_short_name.split('#').reduce((acc, val, i) => {
              acc[val] = valueArray[i] !== '' ? valueArray[i] : '';
              return acc;
            }, {});

            return { ...asset, ...values };
          });

          const flag = {};
          for (let i = 0; i < refAssets.length; i++) {
            const currentObj = refAssets[i];
            const currentXValue = currentObj.parent_line;
            if (currentXValue > 0 && !flag[currentXValue] && line) {
              const generatedRow = newDataRowLine(
                line,
                refAssets,
                currentXValue
              );
              refAssets.splice(i, 0, { ...generatedRow });
              flag[currentXValue] = true;
              i++;
            }
          }

          if (!inputdata.appraiser || !inputdata.area || !startingRefIndex) {
            if(startingRefIndex) {
              let refCounter = startingRefIndex;
              refAssets.forEach((item) => {
                if (item.parent_line === 0) {
                  item.ref = refCounter++;
                }
              });
            }
          }

          let filteredRefAssets = refAssets;
          // todo check here
          if (inputdata.location && inputdata.location > 0) {
            filteredRefAssets = refAssets.filter(
              (item) => +item.location_id === +inputdata.location
            );
          } else if (filterLocation > 0) {
            filteredRefAssets = refAssets.filter(
              (item) => +item.location_id === +filterLocation
            );
          }

          if (!valueCode.length) {
            const locationPriceData = await fetchAssetDetails({
              project_id_crm: decodedProjectId,
              type: 'line',
            });
            const valueCodeRes = locationPriceData?.locationPriceValues?.reduce(
              (acc, curr) => [...acc, curr.value_short_name],
              []
            );
            setValueCode(valueCodeRes);
            const newOrders = [...orders, ...order, ...valueCodeRes];
            const myTableProps = generateUpdatedTableProps(newOrders);
            const CURRENT_PAGE = localStorage.getItem('current_active_page') ? +localStorage.getItem('current_active_page') :null
            CURRENT_PAGE ? myTableProps.paging.pageIndex = CURRENT_PAGE : null
            changeColumnChooserProps({
              ...myTableProps,
              data: filteredRefAssets,
            });
            dispatchAction(assetsListActions.setDoFetchAssetList(false));
          } else {
            const myOrders = [...orders, ...order, ...valueCode];
            if (inputdata.show_assets) {
              filteredRefAssets = filteredRefAssets.filter(({type}) => type != 'line')
            }
            const myTableProps = generateUpdatedTableProps(myOrders); // const myTableProps = generateUpdatedTableProps(orders);
            const CURRENT_PAGE = localStorage.getItem('current_active_page') ? +localStorage.getItem('current_active_page') :null
            CURRENT_PAGE ? myTableProps.paging.pageIndex = CURRENT_PAGE : null
            changeColumnChooserProps({
              ...myTableProps,
              data: filteredRefAssets,
            });
            dispatchAction(assetsListActions.setDoFetchAssetList(false));
          }
          setRows(filteredRefAssets);

          // setLines(line);
          setIsFetchingList(false);
          setLastGetAssetData(false);
        setGetAssetListData(false);
          // handleResetSearch();
        }
      } catch (error) {
        console.error('Error fetching asset list:', error);
        dispatchAction(assetsListActions.setDoFetchAssetList(false));
        setGetAssetListData(false);
        setLastGetAssetData(false);
      } finally {
        const selectedArea = localStorage.getItem('selectedArea') // !to filter out assets based on area
        const selectedAppraiser = localStorage.getItem('selectedAppraiser') // !to filter out assets based on appraiser
        if (
          inputdata.appraiser || inputdata.area || selectedArea || (inputdata.search && isGlobalSearch) || selectedAppraiser || inputdata.hide_assets || inputdata.delete || inputdata.show_assets
        ) {
          if (columnChooserProps.columns[2].visible === false) return;
          const updatedTableConfig = { ...columnChooserProps };
          updatedTableConfig.columns[2].visible = false;
          changeColumnChooserProps(updatedTableConfig);
        } else {
          if (columnChooserProps.columns[2].visible === true) return;
          const updatedTableConfig = { ...columnChooserProps };
          updatedTableConfig.columns[2].visible = true;
          changeColumnChooserProps(updatedTableConfig);
        }
      }
      if (selectedEditableCell) {
        changeColumnChooserProps({
          ...columnChooserProps,
          editableCells: [selectedEditableCell],
        });
      }
    };

    fetchData();
  }, [
    getAssetListData,
    doFetchAssetList,
    isGlobalSearch,
    isFetchingList,
    inputdata,
    setRows,
    // setLines,
    lastGetAssetData,
    setLastGetAssetData,
    handleResetSearch,
    dispatchAction,
    assetsListActions,
    setGetAssetListData,
    fetchAssetList,
    handleCount,
    extractLineAndAsset,
    assetsState?.getAssetListData?.getLocationtData
  ]);

  // let assetDetailData = {
  //   type: 'line',
  //   project_id_crm: decodedProjectId,
  // };
  // useEffect(() => {
  //   if (!fetchAssetDetailsRequest) {
  //     fetchAssetDetails(assetDetailData).then((res) => {
  //       setAssetDetails(res);
  //       const valueCodeRes = res?.locationPriceValues.reduce(
  //         (acc, curr) => [...acc, curr.value_short_name],
  //         []
  //       );
  //       setValueCode(valueCodeRes);
  //       setFetchAssetDetailsRequest(true);
  //     });
  //   }
  // }, []); //had an unnecessary dependency

  useEffect(() => {
    let typeId = localStorage.getItem('type_id');
    let currentTypeRef = localStorage.getItem('current_type_ref');
    let typeIdForEdit = localStorage.getItem('typeIdForEdit');

    if (typeId) {
      localStorage.removeItem('current_type_ref');
      localStorage.removeItem('typeIdForEdit');
    } else if (currentTypeRef) {
      localStorage.removeItem('type_id');
      localStorage.removeItem('typeIdForEdit');
    } else if (typeIdForEdit) {
      localStorage.removeItem('current_type_ref');
      localStorage.removeItem('type_id');
    }
  }, [doFetchAssetList, isGlobalSearch]); //had an unnecessary dependency

  useEffect(() => {
    if (assetsState?.postRenameArea?.postRenameArea?.status === 200) {
      setAreaEdit(false);
      setUpdateArea(null);
    }
  }, [assetsState?.postRenameArea?.postRenameArea]);
  const rowElementAttributes = (props) => {
    const { rowData } = props;
    let typeId; // ! if we are searching for an asset and we find it then we clear out the rest of the localStorage fields that refer to the current asset
    if (asset_ids) {
      const foundAsset = rows.find(({ asset_id }) => asset_id == asset_ids);
      if (foundAsset && foundAsset.type_id) {
        localStorage.removeItem('current_type_ref');
        localStorage.removeItem('typeIdForEdit');
        localStorage.setItem('alreadyScrolled', 0); // ! only in these two cases because we now need to manually reset it
        typeId = foundAsset.type_id;
        localStorage.setItem('type_id', typeId);
      }
    } else if (refs) {
      const foundRef = rows.find(({ ref }) => +ref == +refs);
      if (foundRef && foundRef.type_id) {
        localStorage.removeItem('current_type_ref');
        localStorage.removeItem('typeIdForEdit');
        localStorage.setItem('alreadyScrolled', 0); // ! only in these two cases because we now need to manually reset it
        typeId = foundRef.type_id;
        localStorage.setItem('type_id', typeId);
      }
    } else {
      if (localStorage.getItem('current_type_ref')) {
        const foundRow = rows.find(({ ref }) => +ref === +localStorage.getItem('current_type_ref'));
        typeId = foundRow?.type_id;
      } else if (localStorage.getItem('type_id')) {
        typeId = localStorage.getItem('type_id');
      } else if (localStorage.getItem('typeIdForEdit')) {
        typeId = localStorage.getItem('typeIdForEdit');
      } else {
        const foundAsset = rows.find(({ asset_id }) => asset_id == asset_ids);
        typeId = foundAsset?.type_id;
      }
    }


    if (typeId && +typeId === +rowData?.type_id) {
      // localStorage.removeItem('alreadyScrolled')
      return {
        id: 'highlighted_row',
        className: `highlighted-row ${rowData?.parent_line > 0 ? 'group-line-child-row' : null}`,
      };
    }



    useEffect(() => {
      const alreadyScrolled = +localStorage.getItem('alreadyScrolled');
      const testTableProps = {...columnChooserProps}
      delete testTableProps.paging
      const finalTableRowData = kaPropsUtils.getData(testTableProps)
      // console.log('testing the getData Property', finalTableRowData, testTableProps, columnChooserProps)
      const getTypeId = () => {
        if (localStorage.getItem('current_type_ref')) {
          return columnChooserProps?.data?.find(({ ref }) => +ref === +localStorage.getItem('current_type_ref'))?.type_id;
        }
        if (localStorage.getItem('type_id')) {
          return localStorage.getItem('type_id');
        }
        if (localStorage.getItem('typeIdForEdit')) {
          return localStorage.getItem('typeIdForEdit');
        }
        if (asset_ids) {
          return columnChooserProps?.data?.find(({ asset_id }) => +asset_id === +asset_ids)?.type_id;
        }
        if (refs) {
          return columnChooserProps?.data?.find(({ ref }) => +ref === +refs)?.type_id;
        }
        return null;
      };

      const typeId = getTypeId();

      if (asset_ids && !columnChooserProps?.data?.find(({ asset_id }) => +asset_id === +asset_ids)?.type_id) {
        setMessage('Please enter valid Asset Id.');
        setStatus('warning');
      }

      if (refs && !columnChooserProps?.data?.find(({ ref }) => +ref === +refs)?.type_id) {
        setMessage('Please enter valid Ref#.');
        setStatus('warning');
      }

      if (typeId && (!alreadyScrolled || asset_ids || refs) && columnChooserProps?.data?.length) {
        const timeoutId = setTimeout(() => {
          const currentPageByRowIndex = scrollToHighlightedRow(typeId, finalTableRowData);
          if (columnChooserProps.paging.pageIndex !== currentPageByRowIndex && !alreadyScrolled) {
            changeColumnChooserProps({
              ...columnChooserProps,
              paging: { ...columnChooserProps.paging, pageIndex: currentPageByRowIndex },
            });
          }

          localStorage.setItem('alreadyScrolled', 1);
        }, 2000);

        return () => clearTimeout(timeoutId);
      }
    }, [asset_ids, refs, columnChooserProps, changeColumnChooserProps]);
    //   useEffect(() => {
    //     const PAGE_SIZE = localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50
    //     const alreadyScrolled = +localStorage.getItem('alreadyScrolled')
    //     if(localStorage.getItem('current_type_ref')) setTimeout(() => {        
    //     // ! this is the scroll to current row functionality with react mixed in with some vanilla DOM manipulation
    //   if((!alreadyScrolled || asset_ids || refs) && rows.length) {
    //     // Get the type ID based on different conditions
    //     let typeId;
    //     if (localStorage.getItem('current_type_ref')) {
    //       typeId = rows.find(({ ref }) => +ref === +localStorage.getItem('current_type_ref'))?.type_id;
    //     } else if(localStorage.getItem('type_id')) {
    //       typeId = localStorage.getItem('type_id')
    //     } else if (localStorage.getItem('typeIdForEdit')) {
    //       typeId = localStorage.getItem('typeIdForEdit')
    //     } else if (asset_ids) {
    //       typeId = rows.find(({ asset_id }) => +asset_id === +asset_ids)?.type_id
    //     } else if (refs) {
    //       typeId = rows.find(({ ref }) => +ref === +refs)?.type_id
    //     }
    //     if(asset_ids && !rows.find(({asset_id}) => +asset_id == +asset_ids)?.type_id) {
    //       setMessage('Please enter valid Asset Id.');
    //       setStatus('warning');
    //     }
    //     if(refs && !rows.find(({ref}) => +ref == +refs)?.type_id) {        
    //       setMessage('Please enter valid Ref#');
    //       setStatus('warning');
    //     } else {
    //       // localStorage.removeItem('type_id')
    //       // localStorage.setItem('type_id', typeId) // ! Just to maintain one clear value, will remove duplicate variables which are adding too many cases soon
    //       // localStorage.removeItem('typeIdForEdit')
    //       // localStorage.removeItem('current_type_ref')
    //     }
    //     if(!typeId) return
    //     const currentRowIndex = rows.findIndex(({type_id}) => +type_id === +typeId) + 1 // this is now the position of the item in the table
    //     // const currentPageByRowIndex = Math.floor(currentRowIndex / +PAGE_SIZE)
    //     const divisionResult = currentRowIndex / +PAGE_SIZE;
    //     const currentPageByRowIndex = divisionResult % 1 >= 0.95 ? Math.ceil(divisionResult) : Math.floor(divisionResult);
    //     if(columnChooserProps.paging.pageIndex != currentPageByRowIndex && !alreadyScrolled){
    //       changeColumnChooserProps({...columnChooserProps, paging: {...columnChooserProps.paging, pageIndex: currentPageByRowIndex}})
    //     }
    //     if (typeId) {
    //       const highlightedRow = document.getElementById('highlighted_row'); // ! I know it's pretty ugly and unconventional to use DOm manipulation in react but the case
    //       if (highlightedRow) {
    //         highlightedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //       } else {
    //         localStorage.removeItem('type_id')
    //         localStorage.removeItem('typeIdForEdit')
    //         localStorage.removeItem('current_type_ref')
    //       }
    //       if (!typeId) return
    //       const currentRowIndex = rows.findIndex(({ type_id }) => +type_id === +typeId) + 1 // this is now the position of the item in the table
    //       const currentPageByRowIndex = Math.floor(currentRowIndex / +PAGE_SIZE)
    //       if (columnChooserProps.paging.pageIndex != currentPageByRowIndex && !!alreadyScrolled) {
    //         changeColumnChooserProps({ ...columnChooserProps, paging: { ...columnChooserProps.paging, pageIndex: currentPageByRowIndex } })
    //       }
    //       if (typeId) {
    //         const highlightedRow = document.getElementById('highlighted_row'); // ! I know it's pretty ugly and unconventional to use DOm manipulation in react but the case
    //         if (highlightedRow) {
    //           highlightedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //         } else {
    //           console.log('not handled')
    //         }
    //       }
    //     }
    //     setTimeout(() => {
    //     localStorage.setItem('alreadyScrolled', 1)
    //   }, 3000); // ! might need to tweak
    //   }
    //     }, 3000);

    // }, [rows, asset_ids, refs]);

    // useEffect(() => {
    //   return () => {
    //     localStorage.removeItem('alreadyScrolled');
    //   };
    // }, []);


    const name = parseInt(refs) === parseInt(rowData.ref);
    const nameA = parseInt(asset_ids) === parseInt(rowData.asset_id);

    const className =
      (name || nameA)
        ? 'highlighted-row'
        : `${rowData?.parent_line > 0 ? 'group-line-child-row' : ''}`;

    // const currentReferenceItem =name ?? nameA ? {blockRef} : ''

    if (nameA || name) {
      localStorage.removeItem('type_id');
      localStorage.removeItem('typeIdForEdit');
      localStorage.removeItem('current_type_ref');
      return {
        className: `${className} highlighted-row`,
        // ref: currentReferenceItem,
      };
    }
    return {
      className,
    };
  };
  //set method for setting click on dropdown and value on class
  const handleToggleClass = (id) => {
    const currentId = rows?.find((item) => item.type_id === id);
    setToggleClass(!toggleClass);
    setCurrentRowId(currentId);
  };
  // in every case type set 'asset' if the type is line
  const handleClassChange = async (val, props) => {
    let postAssetClassData = {
      key: 'asset_class',
      key_value: val,
      modified_by: Number(userId),
      type: props?.rowData.type,
      type_id: props?.rowKeyValue,
      update_type: 'field',
    };
    let allRows = [...rows];
    for (const row of allRows) {
      if (row.type_id === props.rowKeyValue) {
        row.asset_class_code = val;
      }
    }
    
    // dispatchAction(postAssetClassAction(postAssetClassData, 'class'));
    let currentRows = [...rows];
    const currentColumnState = {...columnChooserProps}
    try {
      setRows(allRows);
      changeColumnChooserProps({...columnChooserProps, data: allRows})
      const res = await updateAssetClass(postAssetClassData)
      console.log(res)
      
    } catch (error) {
      setMessage('Something wen\'t wrong')
      setStatus('warning')
      setRows(currentRows);
      changeColumnChooserProps(currentColumnState)
    }

    setToggleClass(false);
    const filteredRefAssets = columnChooserProps.data;
    const rowIndex = filteredRefAssets.findIndex(
      ({ type_id }) => type_id === props.rowKeyValue
    );
    if (rowIndex !== -1) {
      filteredRefAssets[rowIndex].asset_class_code = val;
    }

    changeColumnChooserProps({
      ...columnChooserProps,
      data: filteredRefAssets,
    });
  };

  //set method for setting click on dropdown and value on condition
  const handleToggleCondition = (id) => {
    const currentIdCondition = rows?.find((item) => item.type_id === id);
    setToggleCondition(!toggleCondition);
    setCurrentRowIdCondition(currentIdCondition);
  };

  const handleConditionChange = async (val, props) => {
    const key_value = assetsState?.getConditions?.getConditions?.find(
      (item) => item.value === val
    )?.id;
    let postConditionData = {
      key: 'condition',
      key_value,
      modified_by: Number(userId),
      type: props.rowData.type,
      type_id: props.rowKeyValue,
      update_type: 'field',
    };
    let allRows = [...rows];
    for (const row of allRows) {
      if (row.type_id === props.rowKeyValue) {
        row.condition_value = val;
      }
    }
    let currentRows = [...rows];
    const currentColumnState = {...columnChooserProps}
    try {
      setRows(allRows);
      changeColumnChooserProps({...columnChooserProps, data: allRows})
      const res = await updateAssetClass(postConditionData)
    } catch (error) {
      setMessage('Something wen\'t wrong')
      setStatus('warning')
      setRows(currentRows);
      changeColumnChooserProps(currentColumnState)
    }
    // dispatchAction(postAssetClassAction(postConditionData, 'condition'));
    setToggleCondition(false);
  };

  const handleCheck = (rowId, flagId) => async () => {
    try {
      const isFlagged = flagId === 1 ? 0 : 1;
      const data = {
        modified_by: Number(userId),
        type_id: rowId,
        flagged: isFlagged,
      };
      let allRows = [...columnChooserProps?.data];
      for (const row of allRows) {
        if (row.type_id === rowId) {
          row.flagged = isFlagged;
        }
      }
      setRows(allRows);
      await postFlagged(data); //! we no longer wait for the flag to update but rather update the table and if something does go wrong we handle it in the catch block
    } catch (error) {
      const isFlagged = flagId === 1 ? 1 : 0; //! we revert the action if the api fails, so if we flagged the asset we un-flag it and vice-versa
      let allRows = [...rows];
      for (const row of allRows) {
        if (row.type_id === rowId) {
          row.flagged = isFlagged;
        }
      }
      setRows(allRows);
    }
  };

  const handleEditArea = (name, props) => {
    setUpdateArea('');
    setAreaEdit(!areaEdit);
    setAreaName(name[1]);
    setEnable(true);
    columnChooserProps.data?.map(({ area, location_address, location_id }) => {
      if (area === name[1] && location_address === name[0]) { // !only checking area name was not a valid way to do it
        setLocationValue(location_id);
      }
    });
  };
  const handleChangeAreaName = (e) => {
    let value = e.target.value;
    let flag = value.trim().toLowerCase() === areaName.toLowerCase();
    if (flag) {
      setEnable(true);
      if (value?.length <= 250) {
        setUpdateArea(value);
      }
    } else {
      setEnable(false);
      if (value?.length <= 250) {
        setUpdateArea(value);
      }
    }
  };

  const checkType = (value) => {
    let inputValue;
    let digitRegex = /^[0-9]+$/;
    let valid = digitRegex.test(value);
    if (valid) {
      inputValue = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      inputValue = value;
    }
    return inputValue;
  };
  const handleUpdateArea = () => {
    let data = {
      action: 'changeAreaName',
      editAreaLocId: locationValue,
      editAreaName: areaName,
      new_name: updateArea,
    };
    getAssetList(data).then((res) => {
      if (res === undefined) {
        setMessage('Something Went Wrong');
        setStatus('warning');
      } else {
        setMessage('Area Renamed Successfully!');
        setStatus('success');
        dispatchAction(assetsListActions.setDoFetchAssetList(true));
        setAreaEdit(false);
        setAreaName('');
        setUpdateArea('');
      }
    });
  };

  // const isMovement = (objects, index) => {
  //   let dropArray = objects;
  //   console.log("🚀 ~ isMovement ~ dropArray:", dropArray)
  //   let movement;

  //   for (let i = 0; i < dropArray?.length; i++) {
  //     if (dropArray[i] > index) {
  //       movement = 'above';
  //     } else {
  //       movement = 'below';
  //     }
  //     // Assuming you want to return the movement for the first index
  //     return movement;
  //   }
  // };

  const isDropAllowed = (objects, dropTo) => {
    let dropArray = objects?.dragFromArray;
    let flag;

    let minRange = dropArray[0].dragFrom;
    let maxRange = dropArray[dropArray.length - 1].dragFrom;
    // for (let i = 0; i < dropArray.length-1; i++) {
    if (+dropTo >= minRange && +dropTo <= maxRange) {
      flag = false;
    } else {
      flag = true;
    }
    // }
    return flag;
  };

  // const calculateLineRows = (prop) => {
  //   const lineRowIds = columnChooserProps.data.filter(({ parent_line }) => parent_line === prop.rowData.type_id)
  //   // return 
  // }
  // Child Components
  const childComponents = {
    cell: {
      elementAttributes: (props) => ({
        className:
          props.rowData?.type === 'line'
            ? props.rowData?.ref === refs ||
              props.rowData.asset_id === asset_ids
              ? 'highlighted-row'
              : 'red'
            : '',
        onDrop: (e, extendedEvent) => {
          //fetching the type id of dragded row
          const draggableKeyValue = JSON.parse(
            e.dataTransfer.getData('ka-draggableKeyValue')
          );
          const dragRow = [];
          const dragLine = [];
          let { type } = columnChooserProps?.data?.find((item) => +item?.type_id === +draggableKeyValue) || {};
          // let flag= false
          let flag =
            columnChooserProps?.selectedRows?.includes(draggableKeyValue);
          if (columnChooserProps?.selectedRows?.length > 1) {
            if (flag) {
              columnChooserProps?.selectedRows?.map((item) => {
                columnChooserProps?.data?.map((d) => {
                  if (d?.type_id === item) {
                    let obj = {
                      areaFrom: d?.area,
                      // dragFromAreaOrder: d?.area_order,
                      dragFrom: d?.area_asset_order,
                      locationIdFrom: d.location_id,
                      parent_line: d?.parent_line,
                      type_id: d.type_id,
                    };
                    if (type === 'asset') {
                      obj.dragFromAreaOrder = d?.area_order;
                    }
                    dragRow.push(obj);
                  }
                });
              });
            } else {
              let dragRowData = columnChooserProps?.data?.find(
                ({ type_id }) => type_id === draggableKeyValue
              );
              let obj = {
                areaFrom: dragRowData?.area,
                // dragFromAreaOrder: dragRowData?.area_order,
                dragFrom: dragRowData?.area_asset_order,
                locationIdFrom: dragRowData.location_id,
                parent_line: dragRowData?.parent_line,
                type_id: dragRowData?.type_id,
              };
              if (type === 'asset') {
                obj.dragFromAreaOrder = d?.area_order;
              }

              dragRow.push(obj);
            }

            // dragRow.sort(
            //   (a, b) =>
            //     a.area_asset_order - b.area_asset_order
            // );
            // const compare = (a, b) => {
            //   // Choose the property for comparison (dragFrom or area_asset_order)
            //   const property = 'dragFrom'; // or 'area_asset_order'
            //   return a[property] - b[property];
            // };

            // // // Sort the dragRow array based on the chosen property
            // dragRow.sort(compare);
          } else {
            columnChooserProps?.data?.map((d) => {
              if (d.type_id === draggableKeyValue) {
                let obj = {
                  areaFrom: d?.area,
                  dragFrom: d?.area_asset_order,
                  locationIdFrom: d.location_id,
                  parent_line: d?.parent_line,
                  type_id: d.type_id,
                };
                if (type === 'asset') {
                  obj.dragFromAreaOrder = d?.area_order;
                }

                dragRow.push(obj);
              }
            });
          }
          const indexOfExtendRow = columnChooserProps?.data?.findIndex(item => +item?.type_id === +extendedEvent?.childProps?.rowData?.type_id);
          const indexOfDraggableRow = columnChooserProps?.data?.findIndex(item =>+item?.type_id=== +draggableKeyValue);
          const movement = indexOfDraggableRow < indexOfExtendRow ? 'below' : 'above'

          const dropData = {
            areaOrderTo: extendedEvent?.childProps?.rowData?.area_order,
            areaTo: extendedEvent?.childProps?.rowData?.area,
            dragFromArray: dragRow.sort((a,b) => a.dragFrom - b.dragFrom),
            draggedLine: type == 'line' ? draggableKeyValue?.toString() : '',
            dropTo: extendedEvent?.childProps?.rowData?.area_asset_order,
            lineId: Number(extendedEvent?.childProps?.rowData?.parent_line),
            locationIdTo: extendedEvent?.childProps?.rowData?.location_id,
            movement: movement, // add this because of reordering of assets when drop
            projectIdCrm: decodedProjectId,
            typeIdTo: extendedEvent?.childProps?.rowData?.type_id,
          };
          e.target.classList.add('drop-handle');
          extendedEvent.dispatch('rowDroped', { extendedEvent });
          const isAllowed = isDropAllowed(
            dropData,
            extendedEvent?.childProps?.rowData?.area_asset_order
          );
          let dragFromSame = dropData?.dragFromArray[0].dragFrom;
          let moveOnSameAssetAndLine = extendedEvent?.childProps?.rowData?.area_asset_order
          localStorage.removeItem('current_type_ref');
          localStorage.setItem('type_id', dropData.dragFromArray[0]?.type_id); // add for row color
          if (extendedEvent?.childProps?.rowData?.type === 'line') {
            setMessage('We can not move asset over Line');
            setStatus('warning');
            setLastGetAssetData(true)
          } else {
            if (isAllowed) {
              // dropData.dragFromArray.reverse();
              localStorage.setItem('current_active_page', columnChooserProps.paging.pageIndex)
              dispatchAction(assetDropAction(dropData, type));
            } else {
              if(dragFromSame !== moveOnSameAssetAndLine){ // no msg and no api hit we move the asset and line to the same asset and line
              localStorage.removeItem('current_type_ref');
              localStorage.setItem(
                'type_id',
                dropData.dragFromArray[0]?.type_id
              );
              setMessage(
                'We can not move asset in between the "From" and "Till" range'
              );
              setStatus('warning');
              dispatchAction (assetsListActions.setDoFetchAssetList(true)) // discuss with faraz
              // setGetAssetListData(true);
              }
            else{
              setGetAssetListData(false);
            }
          }
            // setDraggedRow(draggableKeyValue);
          }
        },
        onContextMenu: (event, cellProps) => {
          event.preventDefault(false);
          if (event.button === 2) {
            setRowData(cellProps.childProps.rowData);
            setFlagId(cellProps.childProps.rowKeyValue);
            setParentRowData(cellProps?.childProps?.rowData);
            if (props?.column?.key !== 'add-icon') {
              setPosX(event.clientX);
              setPosY(event.clientY - 160);
              if (event.clientX > 0 || event.clientY > 0) {
                if (cellProps?.childProps?.rowData?.type === 'line') {
                  setOpen(true);
                } else {
                  event.preventDefault(false);
                  setVisible(true);
                }
              }
            }
          }
        },
        onKeyDown: (e) => {
          console.log(e.key)
          console.log(columnChooserProps.columns.findIndex(
                (col) => col.key === props.column.key
              ))
          if (e.key === 'Tab') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              const currentColumnIndex = columnChooserProps.columns.findIndex(
                (col) => col.key === props.column.key
              );
              let nextColumnIndex = currentColumnIndex + 1;
              let currentColumnKeyName;
              let nextRow;
              if (nextColumnIndex >= columnChooserProps.columns.length) {
                nextColumnIndex = columnChooserProps.columns.findIndex((i) => i.key === valueCode[0])
                currentColumnKeyName = columnChooserProps.columns[nextColumnIndex].key
                // nextColumnIndex = 1;
                // currentRowKeyValue = props.rowKeyValue + 1;
                const nextRowIndex = columnChooserProps.data.findIndex(
                  ({ type_id }) => +type_id === +props.rowKeyValue
                );
                if(nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex + 1];
                if (newRow) {
                  nextRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
                }
                // currentColumnKeyName = 'selection-cell';
              } 
              // else if (currentColumnIndex === 1) {
              //   nextColumnIndex = columnChooserProps.columns.findIndex(
              //     (col) => col.key === valueCode[0]
              //   );
              //   currentRowKeyValue = props.rowKeyValue;
              //   currentColumnKeyName = valueCode[0];
              // }
              else {
                nextRow = props.rowKeyValue;
                currentColumnKeyName =
                  columnChooserProps.columns[currentColumnIndex + 1].key;
              }
              // if (props.column.key !== 'selection-cell' && props.value != e.target.value) {
                if (props.value != e.target.value) {
                // because fuck ka-table is why
                setSelectedEditableCell({
                  columnKey: currentColumnKeyName,
                  rowKeyValue: nextRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: currentColumnKeyName,
                    rowKeyValue: nextRow,
                  },
                ],
              });
            }
          } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              // const currentColumnIndex = columnChooserProps.columns.findIndex(
              //   (col) => col.key === props.column.key
              // );
              let nextRow;
              const nextRowIndex = columnChooserProps.data.findIndex(
                ({ type_id }) => +type_id === +props.rowKeyValue
              );
              if (nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex + 1];
                if (newRow) {
                  nextRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
              } else {
                console.log('does this case exist?');
              }
                if (props.value != e.target.value) {
              setSelectedEditableCell({
                columnKey: props.column.key,
                rowKeyValue: nextRow,
              });
              kaTableDispatch({
                columnKey: props.column.key,
                rowKeyValue: props.rowKeyValue,
                type: 'UpdateCellValue',
                value: e.target.value,
              });
            }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: props.column.key,
                    rowKeyValue: nextRow,
                  },
                ],
              });
              console.log('go down');
            }
          } else if (e.key === 'ArrowUp') {
                        e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              // const currentColumnIndex = columnChooserProps.columns.findIndex(
              //   (col) => col.key === props.column.key
              // );
              let previousRow;
              const nextRowIndex = columnChooserProps.data.findIndex(
                ({ type_id }) => +type_id === +props.rowKeyValue
              );
              if (nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex - 1];
                if (newRow) {
                  previousRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
              } else {
                console.log('does this case exist?');
              }
                if (props.value != e.target.value) {
              setSelectedEditableCell({
                columnKey: props.column.key,
                rowKeyValue: previousRow,
              });
              kaTableDispatch({
                columnKey: props.column.key,
                rowKeyValue: props.rowKeyValue,
                type: 'UpdateCellValue',
                value: e.target.value,
              });
            }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: props.column.key,
                    rowKeyValue: previousRow,
                  },
                ],
              });
              console.log('go up');
            }
          } else if (e.key === 'Enter') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              // const currentColumnIndex = columnChooserProps.columns.findIndex(
              //   (col) => col.key === props.column.key
              // );
              let nextRow;
              const nextRowIndex = columnChooserProps.data.findIndex(
                ({ type_id }) => +type_id === +props.rowKeyValue
              );
              if (nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex + 1];
                if (newRow) {
                  nextRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
              } else {
                console.log('does this case exist?');
              }
                if (props.value != e.target.value) {
              setSelectedEditableCell({
                columnKey: props.column.key,
                rowKeyValue: nextRow,
              });
              kaTableDispatch({
                columnKey: props.column.key,
                rowKeyValue: props.rowKeyValue,
                type: 'UpdateCellValue',
                value: e.target.value,
              });
            }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: props.column.key,
                    rowKeyValue: nextRow,
                  },
                ],
              });
              console.log('go down');
            }
          } else if (e.key === 'ArrowRight') {
            e.preventDefault();
            if (valueCode.includes(props.column.key)) {
              const currentColumnIndex = columnChooserProps.columns.findIndex(
                (col) => col.key === props.column.key
              );
              let nextColumnIndex = currentColumnIndex + 1;
              let currentColumnKeyName;
              // let currentRowKeyValue;
              let nextRow;
              if (nextColumnIndex >= columnChooserProps.columns.length) {
                nextColumnIndex = columnChooserProps.columns.findIndex((i) => i.key === valueCode[0])
                currentColumnKeyName = columnChooserProps.columns[nextColumnIndex].key
                console.log("🚀 ~ nextColumnIndex:", nextColumnIndex)
                // 1;
                const nextRowIndex = columnChooserProps.data.findIndex(
                  ({ type_id }) => +type_id === +props.rowKeyValue
                );
                if(nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex + 1];
                if (newRow) {
                  nextRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
                }
              } 
              // else if (currentColumnIndex === 1) {
              //   nextColumnIndex = columnChooserProps.columns.findIndex(
              //     (col) => col.key === valueCode[0]
              //   );
              //   nextRow = props.rowKeyValue;
              //   currentColumnKeyName = valueCode[0];
              // }
              else {
                nextRow = props.rowKeyValue;
                currentColumnKeyName =
                  columnChooserProps.columns[currentColumnIndex + 1].key;
              }
              // if (props.column.key !== 'selection-cell' && props.value != e.target.value) {
                // because fuck ka-table is why
                if (props.value != e.target.value) {
                setSelectedEditableCell({
                  columnKey: currentColumnKeyName,
                  rowKeyValue: nextRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: currentColumnKeyName,
                    rowKeyValue: nextRow,
                  },
                ],
              });
            }
          } else if (e.key === 'ArrowLeft') {
            e.preventDefault()
            if (valueCode.includes(props.column.key)) {
              const currentColumnIndex = columnChooserProps.columns.findIndex(
                (col) => col.key === props.column.key
              );
              let nextColumnIndex = currentColumnIndex - 1;
              let currentColumnKeyName = columnChooserProps.columns[nextColumnIndex].key;
              // let currentRowKeyValue;
              let nextRow = props.rowKeyValue;
              const firstPriceValueColumnIndex = columnChooserProps.columns.findIndex((i) => i.key === valueCode[0])
              console.log("🚀 ~ firstPriceValueColumnIndex:", firstPriceValueColumnIndex)
              if (nextColumnIndex < firstPriceValueColumnIndex) {
                // const lastPriceValueColumnIndex = columnChooserProps.columns.findIndex((i) => i.key === valueCode[valueCode.length -1])
                // nextColumnIndex = lastPriceValueColumnIndex
                currentColumnKeyName = valueCode[valueCode.length -1]
                const nextRowIndex = columnChooserProps.data.findIndex(
                  ({ type_id }) => +type_id === +props.rowKeyValue
                );
                if(nextRowIndex !== -1) {
                let newRow = columnChooserProps.data[nextRowIndex - 1];
                if (newRow) {
                  nextRow = newRow?.type_id;
                } else {
                  console.log('no row after this');
                }
                }
                // console.log("🚀 ~ lastPriceValueColumnIndex:", lastPriceValueColumnIndex)
                // console.log('go up now')
              } else {
                // const nextRowIndex = columnChooserProps.data.findIndex(({type_id}) => +type_id === +props.rowKeyValue)
                // if(nextRowIndex !== -1) {
                // let newRow = columnChooserProps.data[nextRowIndex];
                // if (newRow) {
                //   nextRow = newRow?.type_id;
                // } else {
                //   console.log('no row after this');
                // }
                // }
              }
                if (props.value != e.target.value) {
                              setSelectedEditableCell({
                  columnKey: currentColumnKeyName,
                  rowKeyValue: nextRow,
                });
                kaTableDispatch({
                  columnKey: props.column.key,
                  rowKeyValue: props.rowKeyValue,
                  type: 'UpdateCellValue',
                  value: e.target.value,
                });
              }
              changeColumnChooserProps({
                ...columnChooserProps,
                editableCells: [
                  {
                    columnKey: currentColumnKeyName,
                    rowKeyValue: nextRow,
                  },
                ],
              });
              console.log('go left')
              console.log("🚀 ~ nextRow:", nextRow)
            }
          }
        },
        onClick: (e) => {
          // if (props?.rowKeyValue === 55587) {
          //   kaTableDispatch(updateGroupsExpanded(props.rowKeyValue))
          // }
        },
      }),
    },
    dataRow: {
      elementAttributes: rowElementAttributes,
    },
    noDataRow: {
      content: () => 'No Data Available',
    },
    groupRow: {
      elementAttributes: (props) => ({
        className: `group-row-${props?.column?.key}`,
        hidden:
          (props.column.key === 'location_address' &&
            props.groupKey[props.groupIndex] === undefined) ||
          (props.column.key === 'area' &&
            props.groupKey[props.groupIndex] === '') ||
          !!(
            props?.column?.key === 'parent_line' &&
            props.groupKey[props.groupIndex] <= '0'
          ),
        onClick: (e) => {
          if (props?.column?.key === 'parent_line') {
            kaTableDispatch(updateGroupsExpanded(props.groupKey));
          }
          if (props?.column?.key === 'add-icon') {
            kaTableDispatch(updateGroupsExpanded(props.groupKey));
          }
        },
        onContextMenu: (event, cellProps) => {
          event.preventDefault(false);
        },
      }),
      childComponents: {
        dataRow: {
          elementAttributes: (props) => ({
            className: 'group-line-child-row',
          }),
        },
      },
      contentColSpan: 1,
      content: (props) => {
        switch (props?.column?.key) {
          case 'parent_line':
            return DataRowLine(props);
          default:
            return null;
        }
      },
    },
    groupCell: {
      content: (props) => {
        switch (props?.column?.key) {
          case 'area':
            return (
              <Box className="area_flex">
                <Typography>
                  {props?.groupKey[props.groupIndex]?.length > 100
                    ? props.groupKey[props.groupIndex].slice(0, 30)?.toUpperCase() + '...'
                    : props.groupKey[props.groupIndex]?.toUpperCase()}
                </Typography>{' '}
                <IconButton
                  className="icon_btn edit"
                  onClick={() => handleEditArea(props.groupKey)}
                >
                  <Edit />
                </IconButton>
              </Box>
            );
          default:
            return null;
        }
      },
    },
    cellText: {
      elementAttributes: (props) => ({
        // title: props?.column?.key === 'ref' && props?.rowData?.type === 'asset' ? `Location Name : ${props.rowData?.location_address} \n \n Area Name:${props?.rowData?.area}` : props?.column?.key === 'ref' && props?.rowData?.type === 'line' ? `Location Name : ${props.rowData?.location_address}` : props?.column?.key === 'selection-cell' ? '' : props?.column?.key === 'add-icon' ? '' :
        //   valueCode?.includes(props?.column?.key) ? '' : props?.column?.key === 'flagged' ? '' : props?.column?.key === 'is_not_found' ? '' : props?.column?.key === 'condition_value' ? `${convertConditionValueToCondition(props?.value)}` : props?.column?.key === 'asset_class_code' ? `${convertClassValueToClass(props.value)}` : `${props?.value}` === 'null' ? '' : `${props?.value}`,
        title:
          props?.column?.key === 'ref' && props?.rowData?.type === 'asset'
            ? `Location Name : ${props.rowData?.location_address} \n \n Area Name:${props?.rowData?.area}`
            : props?.column?.key === 'ref' && props?.rowData?.type === 'line'
              ? `Location Name : ${props.rowData?.location_address}`
              : props?.column?.key === 'selection-cell'
                ? ''
                : props?.column?.key === 'add-icon'
                  ? ''
                  : valueCode?.includes(props?.column?.key)
                    ? ''
                    : props?.column?.key === 'flagged'
                      ? ''
                      : props?.column?.key === 'is_not_found'
                        ? ''
                        : props?.column?.key === 'condition_value'
                          ? `${convertConditionValueToCondition(props?.value)}`
                          : props?.column?.key === 'asset_class_code'
                            ? `${convertClassValueToClass(props.value)}`
                            : `${props?.value}` === 'null'
                              ? ''
                              : `${props?.value}`,
      }),
      content: (props) => {
        if (valueCode?.includes(props?.column?.key)) {
          if (props?.rowData?.type === 'line' && props?.value == 0) {
            return <Typography style={{ color: 'transparent' }}>0</Typography>
          }
          if (props?.value?.toString() === 'NaN' || props?.value === '0') {
            return <></>;
          }
          if (
            !props?.value ||
            (!props?.value && props?.rowData?.type === 'line')
          ) {
            return <Typography style={{ color: 'transparent' }}>0</Typography>;
          }
          if (props?.rowData?.type !== 'line') {
            return (
              <Typography>
                {props?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
            );
          } else {
            return (
              <Typography
                style={{
                  color: +props.rowData[`${props.column.key}-color`]
                    ? 'red'
                    : 'black',
                  fontWeight: 'bold',
                }}
              >
                {checkType(props?.value)}
              </Typography>
            );
          }
        }
        if (props?.column?.key === 'selection-cell' && props.rowData?.type == 'line') {
          // setSelectedRow(props.selectedRows[0]) //this is add for insert new line asset custom menu item
          // return <SelectionCell {...props} />;
          //! this is new and needs some well round testing 
          return <SelectionLine
            {...props}
            rows={columnChooserProps?.data} // selected line not check uncheck after drag & drop the line
          />
        } else
          if (props?.column?.key === 'selection-cell') {
            // setSelectedRow(props.selectedRows[0]) //this is add for insert new line asset custom menu item
            return <SelectionCell {...props} />;
          } else if (
            props?.column?.key === 'add-icon' &&
            props?.rowData?.type == 'line'
          ) {
            // return <SwitchIcon handleSelect={handleSelect} showIcon={showIcon} />;
            {/* !just handling it for now, will cleanup later */ }
            return <></>
          } else if (props?.column?.key == 'add-icon') {
            return <></>;
          } else if (
            props?.column?.key == 'quantity' &&
            props.rowData.type === 'asset'
          ) {
            if (props.rowData?.quantity_type === 'lot') {
              return <Typography>{props.rowData?.quantity_type}</Typography>;
            } else {
              return <Typography>{props.value}</Typography>;
            }
          } else if (props?.column?.key === 'serial_number') {
            if (props.rowData?.type === 'line') {
              return <></>;
            }
          } else if (props?.column?.key === 'quantity') {
            if (props.rowData?.type === 'line') {
              return <></>;
            }
          }
          // else if (props?.column?.key === 'line_name') {
          //   if (props.rowData?.type === 'line') {
          //     return <Typography>{props.value}</Typography>
          //   }
          // }
          else if (props?.column?.key === 'flagged') {
            if (props.rowData?.type === 'line') {
              return <></>;
            } else {
              return (
                <>
                  {props?.rowData?.flagged === 1 ? (
                    <IconButton
                      id={props.rowKeyValue}
                      className="icon_btn_flag delete"
                      onClick={handleCheck(
                        props.rowKeyValue,
                        props.rowData.flagged
                      )}
                    >
                      <FlagIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      id={props.rowKeyValue}
                      className="icon_btn_flag_default delete"
                      onClick={handleCheck(
                        props.rowKeyValue,
                        props.rowData.flagged
                      )}
                    />
                  )}
                </>
              );
            }
          } else if (props?.column?.key === 'is_not_found') {
            if (props.value === 2) {
              return <CloseIcon fontSize="small" color="warning" />;
            } else if (props.value === 1) {
              return <CheckIcon fontSize="small" color="success" />;
            }
          }
          // else if (valueCode?.includes(props?.column?.key)) {
          //   return props.value !== '0' ? props.value
          //     // <CustomTextBox value={props.value}/>
          //     : ' ';
          // }
          else if (props?.column?.key === 'asset_class_code') {
            return props.value ? (
              <SelectionDropdown
                selectedValue={props?.value}
                data={assetsState?.assetClass?.assetClass}
                handleChange={(e) => handleClassChange(e, props)}
                handleToggle={(id) => handleToggleClass(id)}
                toggleClass={toggleClass}
                rowId={props.rowKeyValue}
                currentRowId={currentRowId}
              />
            ) : (
              <SelectionDropdown
                selectedValue="NA" //just a placeholder
                // data={assetsState?.assetClass?.assetClass}
                data={[{
        "asset_class_id": null,
        "code": "",
    },...assetsState?.assetClass?.assetClass]}
                handleChange={(e) => handleClassChange(e, props)}
                handleToggle={(id) => handleToggleClass(id)}
                toggleClass={toggleClass}
                rowId={props.rowKeyValue}
                currentRowId={currentRowId}
              />
            );
          } else if (props?.column?.key === 'condition_value') {
            return props.value ? (
              <ConditionDropdown
                selectedValueCondition={props?.value}
                conditionData={assetsState?.getConditions?.getConditions}
                handleConditionChange={(e) => handleConditionChange(e, props)}
                handleToggleCondition={(id) => handleToggleCondition(id)}
                toggleCondition={toggleCondition}
                rowIdCondition={props.rowKeyValue}
                currentRowIdCondition={currentRowIdCondition}
              />
            ) : (
              <ConditionDropdown
                selectedValueCondition="NA" //just for a placeholder
                conditionData={assetsState?.getConditions?.getConditions}
                handleConditionChange={(e) => handleConditionChange(e, props)}
                handleToggleCondition={(id) => handleToggleCondition(id)}
                toggleCondition={toggleCondition}
                rowIdCondition={props.rowKeyValue}
                currentRowIdCondition={currentRowIdCondition}
              />
            );
          }
      },
    },
    filterRowCell: {
      content: (props) => {
        if (props?.column?.key === 'selection-cell') {
          return <></>;
        } else if (props?.column?.key === 'add-icon') {
          return <></>;
        }
      },
    },
    headCell: {
      elementAttributes: (props) => ({
        title:
          props?.column?.key === 'add-icon' || props?.column?.key === 'selection-cell' ? '' : `${props?.column?.title}`,
      }),
      content: (props) => {
        if (props?.column?.key === 'selection-cell') {
          return (
            <SelectionHeader
              {...props}
              areAllRowsSelected={kaPropsUtils.areAllFilteredRowsSelected(
                columnChooserProps
              )}
            />
          );
        } else if (props?.column?.key === 'add-icon') {
          return (
            <Box className="align_switch">
              {/* <SwitchIcon handleSelect={handleSelect} showIcon={showIcon} /> */}
              <></> {/* ! just handling it for now, will cleanup later*/}
            </Box>
          );
        }
      },
    },
    pagingSizes: {
      elementAttributes: (props) => ({
        title: 'Selected Page',
      }),
      content: (props) => <PageSize {...props} />,
    },
    pagingIndex: {
      elementAttributes: ({ isActive }) => ({
        className: `page-item ${isActive ? 'active' : ''}`,
      }),
    },
    pagingPages: {
      elementAttributes: () => ({
        className: 'pagination',
      }),
    },
  };
  useEffect(() => {
    if (assetsState?.messageData.status === 'success') {
      setMessage(assetsState?.messageData?.message);
      setStatus('success');
      setOpenDialog(false);
      setAddArea('');
      setOpenConfirmation(false);
    }
  }, [assetsState?.messageData]);
  useEffect(() => {
    if (lineState?.messageData.status === 'success') {
      setMessage(lineState?.messageData?.message);
      setStatus('success');
    }
  }, [lineState?.messageData]);

  useEffect(() => {
    // cleanup effect
    return () => {
      localStorage.removeItem('current_active_page')
      dispatchAction(lineListActions.clearMessageData()); // clearing the global state of snackbar
    };
  }, []);

  const updateflagged = async (flagType, id) => {
    const keyExist = columnChooserProps.hasOwnProperty('selectedRows');
    let updateFlaggedIds = [];
    let updateUnFlaggedIds = [];
    if (keyExist) {
      try {
        rows?.map((key) => {
          columnChooserProps?.selectedRows?.map((item) => {
            if (key.type_id === item && key.flagged === 1) {
              updateUnFlaggedIds.push(item);
            }
            // if (key.type_id === item && key.flagged === 0) {
            //   updateFlaggedIds.push(item)
            // }
          });
        });
        rows?.map((key) => {
          columnChooserProps?.selectedRows?.map((item) => {
            if (key.type_id === item && key.flagged === 0) {
              updateFlaggedIds.push(item);
            }
          });
        });
        if (flagType === 'Flag Asset' || flagType === 'UnFlag Asset') {
          let obj = {
            flagged_ids: updateUnFlaggedIds.join(',') ?? flagId,
            projectIdCrm: decodedProjectId,
            unflagged_ids: updateFlaggedIds.join(',') ?? flagId,
          };
          // dispatchAction(postFlaggedAction(obj));
          const response = await postFlagged(obj)
          setGetAssetListData(true)
        }
      } catch (error) {
        console.log('failed to update flag') //! no need to handle it here since we did not update the table anyway
      }
    } else {
      try {
        const isFlagged = id === 1 ? 0 : 1;
        const data = {
          modified_by: Number(userId),
          type_id: flagId,
          flagged: isFlagged,
        };
        let allRows = [...rows];
        for (const row of allRows) {
          if (row.type_id === flagId) {
            row.flagged = isFlagged;
          }
        }
        setRows(allRows);
        changeColumnChooserProps({ ...columnChooserProps, allRows }) // !the table is not getting updated in just this case so I am now updating the tableProps to reflect the changes
        await postFlagged(data); //! we no longer wait for the flag to update but rather update the table and if something does go wrong we handle it in the catch block
      } catch (error) {
        const isFlagged = id === 1 ? 1 : 0; //! we revert the action if the api fails, so if we flagged the asset we un-flag it and vice-versa
        let allRows = [...rows];
        for (const row of allRows) {
          if (row.type_id === flagId) {
            row.flagged = isFlagged;
          }
        }
        setRows(allRows);
        changeColumnChooserProps({ ...columnChooserProps, allRows }) // !the table is not getting updated in just this case so I am now updating the tableProps to reflect the changes
      }
    }
  };

  const handleConfirmation = () => {
    setOpenConfirmation(!openConfirmation);
  };

  const handleDelete = () => {
    let deleteIds = columnChooserProps?.selectedRows?.join(',') ?? flagId;
    dispatchAction(deleteAssetAction(deleteIds));
    // kaTableDispatch(deleteRow(rowData.type_id));
    setVisible(false);
  };

  const handleMenuItem = (key) => {
    const allRows = [...rows];
    const assetRows = allRows.filter(({ type }) => type !== 'line');
    // it was getting impossible to handle the other way around

    localStorage.removeItem('type_id')
    localStorage.removeItem('typeIdForEdit')
    let index;
    assetRows?.map(({ type_id }, i) => {
      if (type_id === rowData?.type_id) {
        index = i;
      }
    });
    setItem(key);
    if (key.name === 'Insert New Area') {
      setOpenDialog(true);
      setVisible(false);
    } else if (key.name === 'Block Move') {
      setOpenDialog(true);
      setVisible(false);
    } else if (key.name === 'Reassign Asset') {
      setOpenDialog(true);
      setVisible(false);
    } else if (key.name === 'Insert New Line') {
      if (selectItems?.length > 0) {
        setInputLineValues({
          asset_class_id: 1,
          condition: 'Good',
          line_text2: ', Consisting of:',
        });
        setOpenDialog(true);
        setVisible(false);
      } else {
        setMessage('Please Select the Asset First');
        setStatus('warning');
      }
    } else if (key === 'Flag Asset') {
      setFlagId(rowData.type_id);
      setVisible(false);
      updateflagged(key, rowData?.flagged);
    } else if (key === 'UnFlag Asset') {
      setFlagId(rowData.type_id);
      setVisible(false);
      updateflagged(key, rowData?.flagged);
    } else if (key.name === 'Delete Asset') {
      handleConfirmation();
    } else if (key.name === 'Copy Asset Below') {
      let obj = {
        copy_type_id: rowData.type_id,
      };
      dispatchAction(copyAssetBelowAction(obj));
      setVisible(false);
    } else if (key.name === 'Insert Asset Above') {
      localStorage.setItem('editMode', 1);
      localStorage.setItem('dropTo', +rowData?.area_asset_order)
      localStorage.setItem('area', rowData.area)
      localStorage.setItem('insertPosition', 'above');
    history.push({ pathname: `asset-detail/` });
    return 
      localStorage.setItem('assetDropTo', +rowData?.area_asset_order)
      setFlagId(rowData.type_id);
      history.push({
        pathname: 'asset-detail',
        state: {
          data: {
            rowData,
            index,
            key,
            rows: assetRows,
            valueCode,
            sleekMode: true,
          },
        },
      });
    } else if (key.name === 'Insert Asset Below') {
      localStorage.setItem('editMode', 1);
      localStorage.setItem('dropTo', +rowData?.area_asset_order);
      localStorage.setItem('insertPosition', 'below');
      localStorage.setItem('area', rowData.area);
      history.push({ pathname: `asset-detail/` });
      // localStorage.setItem('assetDropTo', +rowData?.area_asset_order)
      // setFlagId(rowData.type_id);
      // history.push({
      //   pathname: 'asset-detail',
      //   state: {
      //     data: {
      //       rowData,
      //       index,
      //       key,
      //       rows: assetRows,
      //       valueCode,
      //       sleekMode: true,
      //     },
      //   },
      // });
    } else {
      localStorage.setItem('editMode', 1);
      localStorage.setItem('typeIdForEdit', rowData.type_id);
      // localStorage.removeItem('type_id')
      // todo - still pending as part of the ref on edit asset
    //       var edit_asset_action = [
    //   "Edit Asset",
    //   function ($itemScope, $event, row) {
    //     $scope.flag = false;
    //     row = angular.fromJson(row);
    //     $cookieStore.put("location_id", parseInt(row.location_id));
    //     $location.path("/assetdetail/" + row.type_id);
    //   },
    // ];
    history.push({pathname: `asset-detail/${rowData.type_id}`})
      // const updatedRowData = rows.find(
      //   (data) => data.type_id === rowData.type_id
      // );
      // setIsLoading(true)
      // getAssetInEdit({ ...refPayload, project_id_crm: decodedProjectId, projectId: updatedRowData.project_id, type_id: +updatedRowData?.type_id }).then(({ current_ref_no }) => {
      //   history.push({
      //     pathname: 'asset-detail' + `/${rowData.type_id}`,
      //     state: { data: { rowData: { ...rowData, ref: current_ref_no }, key, index, valueCode, rows: assetRows } },
      //   });
      // })
    }
  };
  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleChangeArea = (e) => {
    let value = e.target.value;
    if (value?.length <= 250) {
      setAddArea(e.target.value);
    }
  };

  const handleAddArea = () => {
    let value = {
      'Forced Liquidation Value': rowData.FLV,
      'Orderly Liquidation Value': rowData.OLV,
    };
    const payload = Object.assign(rowData, { value: value });

    if (!addArea) {
      setMessage('Please Enter Area Name');
      setStatus('warning');
      return;
    }
    let addObj = {
      action: 'insertNewArea',
      new_name: addArea,
      project_id_crm: decodedProjectId,
      row: payload,
    };
    getAssetList(addObj)
      .then((res) => {
        if (res.msgCode === 1) {
          setMessage(res.msg);
          setStatus('warning');
        } else {
          setMessage('Area has been Added Successfully!');
          setStatus('success');
          setAddArea(null);
          dispatchAction(assetsListActions.setDoFetchAssetList(true));
          setOpenDialog(false);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    let priceVal = [];
    const finalValue = assetData?.locationPriceValues;
    finalValue?.forEach((item) => {
      for (const key in inputLineValues) {
        if (item.value_short_name === key) {
          let obj = {
            name: item.value_code,
            value: inputLineValues[key],
          };
          priceVal.push(obj);
        }
      }
    });
    setValue(priceVal);
  }, [inputLineValues]);

  const transformedPriceVal = value?.reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  }, {});

  const handleInsertNewLine = () => {
    // Please enter atleast one field from Make, Text1 or Asset Type.
    if (
      inputLineValues?.line_name ||
      inputLineValues?.make ||
      inputLineValues?.asset_type
    ) {
      let obj = {
        selectedAsset: selectItems,
        asset_type_id: inputLineValues?.asset_type_id,
        asset_class_id: inputLineValues?.asset_class_id,
        condition: inputLineValues?.condition,
        appraiser: rowData?.user_id,
        line_text2: inputLineValues?.line_text2,
        btn: 'inactive',
        priceVal: transformedPriceVal,
        make_id: inputLineValues?.make_id,
        make: inputLineValues?.make,
        line_name: inputLineValues?.line_name,
        asset_no: inputLineValues?.asset_no,
        year: inputLineValues?.year,
        appraiser_note: inputLineValues?.appraiser_note,
        cost: inputLineValues?.cost,
        created_by: Number(userId),
        modified_by: Number(userId),
        action: 'insertNewAsset',
        project_id_crm: decodedProjectId,
      };
      localStorage.setItem('current_active_page', columnChooserProps.paging.pageIndex)
      getAssetList(obj)
        .then((res) => {
          if (res === undefined) {
            setMessage('Something Went Wrong');
            setStatus('warning');
          } else {
            setMessage('Line has been created !!');
            setStatus('success');
            localStorage.removeItem('current_type_ref');
            localStorage.removeItem('typeIdForEdit');
            localStorage.removeItem('type_id');
            dispatchAction(assetsListActions.setDoFetchAssetList(true));
            // setOpenDialog(false);
            setInputLineValues({});
            changeColumnChooserProps({
              ...columnChooserProps,
              selectedRows: [],
            });
            setSelectItems({});
            setItem();
            handleDialog();
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      setMessage(
        'Please enter atleast one field from Make, Text1 or Asset Type'
      );
      setStatus('warning');
    }
  };

  const handleReassign = (data) => {
    getAssetList(data)
      .then((res) => {
        if (res === undefined) {
          setMessage('Something Went Wrong');
          setStatus('warning');
        } else {
          setMessage(res?.msg);
          setStatus('success');
          dispatchAction(assetsListActions.setDoFetchAssetList(true));
          setOpenDialog(false);
        }
      })
      .catch((error) => { });
  };

  const handleMove = (data) => {
    localStorage.setItem('alreadyScrolled', 0)
    dispatchAction(blockMoveAssetAction(data));
    setOpenDialog(false);
  };
  const handleMenuItem2 = (key) => {
    if (key.name === 'Edit Line') {
      history.push({
        pathname: 'line-detail' + `/${flagId}`,
        state: { data: { parentRowData, key, route: 'asset-list' } },
        valueCode: valueCode,
      });
    } else if (key.name === 'Copy Line') {
      history.push({
        pathname: 'line-detail' + `/${flagId}`,
        state: {
          data: {
            parentRowData,
            key,
            route: 'asset-list',
          },
        },
        valueCode: valueCode,
      });
    }
  };
  const handleSnackBarClose = () => {
    setMessage('');
    setStatus('');
    dispatchAction(assetsListActions.clearMessageData());
  };
  // let typeId = localStorage.getItem('type_id');
  // const lineData = rows.find(({type})=> type==='line')
  const getTable = useMemo(() => {
    const PAGE_SIZE = localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50
    const CURRENT_PAGE = localStorage.getItem('current_active_page') ? {pageIndex: +localStorage.getItem('current_active_page')} : null
    // localStorage.setItem('itemsPerPage', columnChooserProps.paging.pageSize);
    return (
      <Table
        {...columnChooserProps}
        paging={{ ...columnChooserProps.paging, pageSize: +PAGE_SIZE, CURRENT_PAGE }}
        childComponents={childComponents}
        dispatch={kaTableDispatch}
        // treeGroupKeyField={'treeGroupId'}
        // groups={[{columnKey: column.key === 'add-icon'}]}
        search={({ searchValue: search, rowData, column }) => {
          if (column.key === rowData?.flagged) {
            return (
              (search === 'false' && !rowData.flagged) ||
              (search === 'true' && rowData.flagged)
            );
          }
        }}
        // searchText={searchValue}
        noData={{
          text: 'No Data Found',
        }}
      />
    );
  }, [columnChooserProps, filterAssetInDataGrid]);

  const handleFocus = () => {
    let updatedInputValues = { ...inputLineValues }
    const allowedValues = ['no value', 'leased', 'not valued'];
    valueCode.forEach((val) => {
      if (allowedValues.includes(inputLineValues[val]?.toLowerCase()?.trim()) || +inputLineValues[val] > 0) {
        // Valid value, do nothing
      } else {
        updatedInputValues[val] = '' // Reset invalid value
      }
    })
    setInputLineValues(updatedInputValues)
  };
  const handleChangeLine = (e) => {
    let value = e.target.value;
    if (e.target.name === 'line_text2') {
      if (value.length < 100) {
        setInputLineValues({ ...inputLineValues, line_text2: e.target.value });
      }
    } else if (e.target.name === 'line_name') {
      if (value.length < 500) {
        setInputLineValues({ ...inputLineValues, line_name: e.target.value });
      }
    } else {
      setInputLineValues({
        ...inputLineValues,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSelectedForLineAndAssetTypeForLine = (selected) => {
    let label
    if (selected[0]?.label) label = true
    setInputValues({
      ...inputValues,
      selectedAssetType: selected,
      asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
      asset_type_id: label ? null : selected[0]?.asset_type_id,
      asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
    })
  }
  const handleKeyDownForTypeAheadForLine = (e) => {
    const {placeholder, value} = e.target
    if (!value) return
    if (e.keyCode === 9) {
      if (placeholder === 'Search Make') {
        const makeId = makeList?.find(({make}) => make === value)?.make_id
        setInputLineValues({...inputLineValues, selectedMake: [{make: value}], make: value, make_id: makeId})
      }
      if (placeholder === 'Search Asset Types') {
        const assetTypeValue = filteredAssetTypes?.find(({asset_type}) => asset_type === value)
        setInputLineValues({...inputLineValues, selectedAssetType: [{asset_type: value}], asset_type: value, asset_type_id: assetTypeValue?.asset_type_id, asset_class_id: assetTypeValue?.class_id})
      }
    }
  }
  const handleCustomTypeAheadForMakeForLine = (makeValue) => {
    if (Array.isArray(makeValue)) return
    setInputLineValues({...inputLineValues, selectedMake: [{make: makeValue}], make: makeValue})
  }
  const handleSelectedForLines = (selected, type) => {
    let label;
    // we set lable in case we are adding a custom value and not one that is already in the list
    if (selected[0]?.label) label = true;
    if (selected.length === 0) return
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case 'make':
        setInputLineValues({
          ...inputLineValues,
          selectedMake: selected,
          make: label ? selected[0]?.label : selected[0]?.make,
          make_id: label ? null : selected[0]?.make_id,
        });
        break;
      case 'assetType':
        setInputLineValues({
          ...inputLineValues,
          selectedAssetType: selected,
          asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
          asset_type_id: label ? null : selected[0]?.asset_type_id,
          asset_class_id: +selected[0]?.class_id ?? 0
        });
        break;
      default:
        break;
    }
  };

  function countOccurrences(arr, val) {
    return arr?.reduce((count, item) => {
      return count + (item === val ? 1 : 0);
    }, 0);
  }

  const assetCount = columnChooserProps?.selectedRows?.map((item) => {
    return rows?.filter((key) => {
      return key.type_id === item && key.type !== 'line';
    }).length;
  });
  const assetCountArray = countOccurrences(assetCount, 1);


  return (
    <DataGridStyled className="rows-reordering-demo" id="my-component">
      {(assetsState?.addNewArea?.loading ||
        assetsState?.getAssetListData?.loading ||
        getAssetListData ||
        assetsState?.dropAssets?.loading ||
        assetsState?.blockMoveAsset?.loading ||
        assetsState?.postAssetClass?.loading ||
        assetsState?.postFlagged?.loading ||
        assetsState?.postRenameArea?.loading ||
        assetsState?.deleteAsset?.loading ||
        doFetchAssetList ||
        (isGlobalSearch && doFetchAssetList) || isLoading || isFetchingList) && (
          <div>
            <Loader />
          </div>
        )}
      <ParentMenu
        visible={open}
        posX={posX}
        posY={posY}
        handleClose={() => setOpen(false)}
        handleMenuItem={handleMenuItem2}
        rowData={rowData}
      />
      <CustomMenu
        visible={visible}
        posX={posX}
        posY={posY}
        handleClose={() => setVisible(false)}
        handleMenuItem={handleMenuItem}
        rowData={rowData}
        inputdata={inputdata}
      />
      {item && item.id == 3 ? (
        <InsertAreaDialog
          openDialog={openDialog}
          item={item}
          handleDialog={handleDialog}
          handleAdd={handleAddArea}
          handleChangeArea={handleChangeArea}
          addArea={addArea}
        />
      ) : (
        ''
      )}
      {item && item.id == 2 ? (
        <BlockMoveDialog
          openDialog={openDialog}
          item={item}
          handleDialog={handleDialog}
          rowData={rowData}
          selectedAsset={selectItems}
          handleMove={handleMove}
          maxRefNo={assetResponse.max_ref_no}
          minRefNo={assetResponse.min_ref_no}
          appraiserData={appraiserData}
        />
      ) : (
        ''
      )}
      {item && item.id == 7 ? (
        <ReassignDialog
          openDialog={openDialog}
          item={item}
          handleDialog={handleDialog}
          handleReassign={handleReassign}
          rowData={rowData}
          assetResponse={assetResponse}
          decodedProjectId={decodedProjectId}
        />
      ) : (
        ''
      )}
      {item && item.id == 4 ? (
        <InsertNewLineDialog
          handleDialog={handleDialog}
          openDialog={openDialog}
          makeList={makeList}
          handleSelected={handleSelectedForLines}
          handleCustomTypeAheadForMakeForLine={handleCustomTypeAheadForMakeForLine}
          handleKeyDownForTypeAheadForLine={handleKeyDownForTypeAheadForLine}
          handleSelectedForLineAndAssetTypeForLine={handleSelectedForLineAndAssetTypeForLine}
          inputValues={inputLineValues}
          handleFocus={handleFocus}
          condition={condition}
          handleChange={handleChangeLine}
          assetTypes={filteredAssetTypes}
          handleInsertNewLine={handleInsertNewLine}
          assetData={assetData}
          rowData={rowData}
          flag="datagrid"
          valueCodes={valueCode}
          item={item}
        />
      ) : (
        // <InsertNewLineDialog
        //   openDialog={openDialog}
        //   item={item}
        //   handleDialog={handleDialog}
        //   rowData={rowData}
        //   // handleChange={handleChangeLine}
        //   handleInsertNewLine={handleInsertNewLine}
        //   valueCodes={valueCode}
        //   flag = 'datagrid'
        //   selectItems={selectItems}
        // />
        ''
      )}
      <EditPopup
        handleEditArea={handleEditArea}
        areaEdit={areaEdit}
        areaName={areaName}
        isEnable={isEnable}
        updateArea={updateArea}
        handleChangeAreaName={handleChangeAreaName}
        handleUpdateArea={handleUpdateArea}
      />
      <ColumnToggle
        tableProps={columnProps}
        dispatch={dispatch}
        columnSetting={columnSetting}
        handleSidebar={handleSidebar}
      />
      {getTable}
      <ConfirmationAlert
        handleDelete={handleDelete}
        openConfirmation={openConfirmation}
        handleConfirmation={handleConfirmation}
        title={`You are about to delete (${(assetCountArray ?? 1)}) assets`}
      />
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </DataGridStyled>
  );
};

export default DataGrid;
