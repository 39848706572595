import {createSlice, current} from '@reduxjs/toolkit'

const initialState = {
  messageData: {
    message: null,
    status: null,
  },
  findReplace: {
    loading: false,
    findReplace: [],
    error: null,
  },
  postFindReplace: {
    loading: false,
    postFindReplace: [],
    error: null,
  },
  postAddAsset: {
    loading: false,
    postAddAsset: [],
    error: null,
  },
  postUpdateAsset: {
    loading: false,
    postUpdateAsset: [],
    error: null,
  },
  assetsByAppraiser: {
    loading: false,
    assetsByAppraiser: [],
    error: null,
  },
  photosByAsset: {
    loading: false,
    photosByAsset: [],
    error: null,
  },
  modelList: [],
  photosByAppraiser: {
    loading: false,
    photosByAppraiser: [],
    error: null,
  },
  photosToAssets: {
    loading: false,
    photosToAssets: [],
    error: null,
  },
  deleteAssignPhotos: {
    loading: false,
    deleteAssignPhotos: [],
    error: null,
  },
  getAssetListData: {
    loading: false,
    getLocationtData: [],
    getAppraiserData: [],
    getAreaData: [],
    getAssetsData: [],
    error: null,
  },
  selectedItems: [],
  assetList: {
    loading: false,
    assetList: [],
    error: null,
  },
  addNewArea: {
    loading: false,
    addNewArea: [],
    error: null,
  },
  postFlagged: {
    loading: false,
    postFlagged: [],
    error: null,
  },
  insertNewLine: {
    loading: false,
    insertNewLine: [],
    error: null,
  },
  deleteAsset: {
    loading: false,
    deleteAsset: [],
    error: null,
  },
  reassignAsset: {
    loading: false,
    reassignAsset: [],
    error: null,
  },
  copyAssetBelow: {
    loading: false,
    copyAssetBelow: [],
    error: null,
  },
  insertAssetAbove: {
    loading: false,
    insertAssetAbove: [],
    error: null,
  },
  insertAssetBelow: {
    loading: false,
    insertAssetBelow: [],
    error: null,
  },
  saveAndCreateNew: {
    loading: false,
    saveAndCreateNew: [],
    error: null,
  },
  saveAndCopyNew: {
    loading: false,
    saveAndCopyNew: [],
    error: null,
  },
  saveAndCopyBelow: {
    loading: false,
    saveAndCopyBelow: [],
    error: null,
  },
  blockMoveAsset: {
    loading: false,
    blockMoveAsset: [],
    error: null,
  },
  doFetchAssetList: true,
  getModelByMake: {
    loading: false,
    getModelByMake: [],
    error: null,
  },
  dropAssets: {
    loading: false,
    dropAssets: [],
    error: null,
  },
  assetClass: {
    loading: false,
    assetClass: [],
    error: null,
  },
  getConditions: {
    loading: false,
    getConditions: [],
    error: null,
  },
  postAssetClass: {
    loading: false,
    postAssetClass: [],
    error: null,
  },
  postRenameArea: {
    loading: false,
    postRenameArea: [],
    error: null,
  },
  deleteInnerAsset: {
    loading: false,
    deleteInnerAsset: [],
    error: null,
  },
}

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    // defining the actions
    setMessageData(state, action) {
      state.messageData.message = action.payload.message
      state.messageData.status = action.payload.status
    },
    setModelList(state, action) {
      state.modelList = action.payload
    },
    clearMessageData(state, action) {
      state.messageData.message = null
      state.messageData.status = null
    },
    findReplaceRequest(state, action) {
      state.findReplace.loading = true
    },
    findReplaceSuccess(state, action) {
      state.findReplace.findReplace = action.payload
      state.findReplace.loading = false
    },
    findReplaceFailure(state, action) {
      state.findReplace.error = action.payload
      state.findReplace.loading = false
    },
    postFindReplaceRequest(state, action) {
      state.postFindReplace.loading = true
    },
    postFindReplaceSuccess(state, action) {
      state.postFindReplace.postFindReplace = action.payload
      state.postFindReplace.loading = false
    },
    postFindReplaceFailure(state, action) {
      state.postFindReplace.error = action.payload
      state.postFindReplace.loading = false
    },
    postAddAssetRequest(state, action) {
      state.postAddAsset.loading = true
    },
    postAddAssetSuccess(state, action) {
      state.postAddAsset.postAddAsset = action.payload
      state.postAddAsset.loading = false
    },
    postAddAssetFailure(state, action) {
      state.postAddAsset.error = action.payload
      state.postAddAsset.loading = false
    },
    postUpdateAssetRequest(state, action) {
      state.postUpdateAsset.loading = true
    },
    postUpdateAssetSuccess(state, action) {
      state.postUpdateAsset.postUpdateAsset = action.payload
      state.postUpdateAsset.loading = false
    },
    postUpdateAssetFailure(state, action) {
      state.postUpdateAsset.error = action.payload
      state.postUpdateAsset.loading = false
    },
    assetsByAppraiserRequest(state, action) {
      state.assetsByAppraiser.loading = true
    },
    assetsByAppraiserSuccess(state, action) {
      state.assetsByAppraiser.assetsByAppraiser = action.payload
      state.assetsByAppraiser.loading = false
    },
    assetsByAppraiserFailure(state, action) {
      state.assetsByAppraiser.error = action.payload
      state.assetsByAppraiser.loading = false
    },
    photosByAssetRequest(state, action) {
      state.photosByAsset.loading = true
    },
    photosByAssetSuccess(state, action) {
      state.photosByAsset.photosByAsset = action.payload
      state.photosByAsset.loading = false
    },
    photosByAssetFailure(state, action) {
      state.photosByAsset.error = action.payload
      state.photosByAsset.loading = false
    },
    photosByAppraiserRequest(state, action) {
      state.photosByAppraiser.loading = true
    },
    photosByAppraiserSuccess(state, action) {
      state.photosByAppraiser.photosByAppraiser = action.payload
      state.photosByAppraiser.loading = false
    },
    photosByAppraiserFailure(state, action) {
      state.photosByAppraiser.error = action.payload
      state.photosByAppraiser.loading = false
    },
    photosToAssetsRequest(state, action) {
      state.photosToAssets.loading = true
    },
    photosToAssetsSuccess(state, action) {
      state.photosToAssets.photosToAssets = action.payload
      state.photosToAssets.loading = false
    },
    photosToAssetsFailure(state, action) {
      state.photosToAssets.error = action.payload
      state.photosToAssets.loading = false
    },
    deleteAssignPhotosRequest(state, action) {
      state.deleteAssignPhotos.loading = true
    },
    deleteAssignPhotosSuccess(state, action) {
      state.deleteAssignPhotos.deleteAssignPhotos = action.payload
      state.deleteAssignPhotos.loading = false
    },
    deleteAssignPhotosFailure(state, action) {
      state.deleteAssignPhotos.error = action.payload
      state.deleteAssignPhotos.loading = false
    },
    getAssetListDataRequest(state, action) {
      state.getAssetListData.loading = true
    },
    getLocationSuccess(state, action) {
      state.getAssetListData.getLocationtData = action.payload
      state.getAssetListData.loading = false
    },
    getAppraiserSuccess(state, action) {
      state.getAssetListData.getAppraiserData = action.payload
      state.getAssetListData.loading = false
    },
    getAreaSuccess(state, action) {
      state.getAssetListData.getAreaData = action.payload
      state.getAssetListData.loading = false
    },
    getAssetsSuccess(state, action) {
      state.getAssetListData.getAssetsData = action.payload
      state.getAssetListData.loading = false
    },
    getAssetListDataFailure(state, action) {
      state.getAssetListData.error = action.payload
      state.getAssetListData.loading = false
    },
    assetListRequest(state, action) {
      state.assetList.loading = true
    },
    assetListSuccess(state, action) {
      state.assetList.reportsList = action.payload
      state.assetList.loading = false
    },
    assetListFailure(state, action) {
      state.assetList.error = action.payload
      state.assetList.loading = false
    },
    addNewAreaRequest(state, action) {
      state.addNewArea.loading = true
    },
    addNewAreaSuccess(state, action) {
      state.addNewArea.addNewArea = action.payload
      state.addNewArea.loading = false
    },
    addNewAreaFailure(state, action) {
      state.addNewArea.error = action.payload
      state.addNewArea.loading = false
    },
    postFlaggedRequest(state, action) {
      state.postFlagged.loading = true
    },
    postFlaggedSuccess(state, action) {
      state.postFlagged.postFlagged = action.payload
      state.postFlagged.loading = false
    },
    postFlaggedFailure(state, action) {
      state.postFlagged.error = action.payload
      state.postFlagged.loading = false
    },
    insertNewLineRequest(state, action) {
      state.insertNewLine.loading = true
    },
    insertNewLineSuccess(state, action) {
      state.insertNewLine.insertNewLine = action.payload
      state.insertNewLine.loading = false
    },
    insertNewLineFailure(state, action) {
      state.insertNewLine.error = action.payload
      state.insertNewLine.loading = false
    },
    deleteAssetRequest(state, action) {
      state.deleteAsset.loading = true
    },
    deleteAssetSuccess(state, action) {
      state.deleteAsset.deleteAsset = action.payload
      state.deleteAsset.loading = false
    },
    deleteAssetFailure(state, action) {
      state.deleteAsset.error = action.payload
      state.deleteAsset.loading = false
    },
    reassignAssetRequest(state, action) {
      state.reassignAsset.loading = true
    },
    reassignAssetSuccess(state, action) {
      state.reassignAsset.reassignAsset = action.payload
      state.reassignAsset.loading = false
    },
    reassignAssetFailure(state, action) {
      state.reassignAsset.error = action.payload
      state.reassignAsset.loading = false
    },
    copyAssetBelowRequest(state, action) {
      state.copyAssetBelow.loading = true
    },
    copyAssetBelowSuccess(state, action) {
      state.copyAssetBelow.copyAssetBelow = action.payload
      state.copyAssetBelow.loading = false
    },
    copyAssetBelowFailure(state, action) {
      state.copyAssetBelow.error = action.payload
      state.copyAssetBelow.loading = false
    },
    insertAssetAboveRequest(state, action) {
      state.insertAssetAbove.loading = true
    },
    insertAssetAboveSuccess(state, action) {
      state.insertAssetAbove.insertAssetAbove = action.payload
      state.insertAssetAbove.loading = false
    },
    insertAssetAboveFailure(state, action) {
      state.insertAssetAbove.error = action.payload
      state.insertAssetAbove.loading = false
    },
    insertAssetBelowRequest(state, action) {
      state.insertAssetBelow.loading = true
    },
    insertAssetBelowSuccess(state, action) {
      state.insertAssetBelow.insertAssetBelow = action.payload
      state.insertAssetBelow.loading = false
    },
    insertAssetBelowFailure(state, action) {
      state.insertAssetBelow.error = action.payload
      state.insertAssetBelow.loading = false
    },
    saveAndCreateNewRequest(state, action) {
      state.saveAndCreateNew.loading = true
    },
    saveAndCreateNewSuccess(state, action) {
      state.saveAndCreateNew.saveAndCreateNew = action.payload
      state.saveAndCreateNew.loading = false
    },
    saveAndCreateNewFailure(state, action) {
      state.saveAndCreateNew.error = action.payload
      state.saveAndCreateNew.loading = false
    },
    saveAndCopyNewRequest(state, action) {
      state.saveAndCopyNew.loading = true
    },
    saveAndCopyNewSuccess(state, action) {
      state.saveAndCopyNew.saveAndCopyNew = action.payload
      state.saveAndCopyNew.loading = false
    },
    saveAndCopyNewFailure(state, action) {
      state.saveAndCopyNew.error = action.payload
      state.saveAndCopyNew.loading = false
    },
    saveAndCopyBelowRequest(state, action) {
      state.saveAndCopyBelow.loading = true
    },
    saveAndCopyBelowSuccess(state, action) {
      state.saveAndCopyBelow.saveAndCopyBelow = action.payload
      state.saveAndCopyBelow.loading = false
    },
    saveAndCopyBelowFailure(state, action) {
      state.saveAndCopyBelow.error = action.payload
      state.saveAndCopyBelow.loading = false
    },
    blockMoveAssetRequest(state, action) {
      state.blockMoveAsset.loading = true
    },
    blockMoveAssetSuccess(state, action) {
      state.blockMoveAsset.blockMoveAsset = action.payload
      state.blockMoveAsset.loading = false
    },
    blockMoveAssetFailure(state, action) {
      state.blockMoveAsset.error = action.payload
      state.blockMoveAsset.loading = false
    },
    setAllItemsSelected(state, action) {
      const {payload} = action
      if (payload.includes('asset-list')) {
        const allItems = current(state.assetList.assetList)
        const allIds = allItems.map((elem) => elem.location_id)
        state.selectedItems = allIds
        return
      }
      if (payload.includes('findreplace-imported-asset')) {
        const allItems = current(state.findReplace.findReplace)
        const allIds = allItems.map((elem) => elem.type_id)
        state.selectedItems = allIds
        return
      }
      if (payload.includes('findreplace')) {
        const allItems = current(state.findReplace.findReplace)
        const allIds = allItems.map((elem) => elem.type_id)
        state.selectedItems = allIds
        return
      }
    },
    setSelectedItems(state, action) {
      if (action.payload.type === 'add') {
        state.selectedItems = [...state.selectedItems, action.payload.id]
      } else {
        const filteredList = state.selectedItems.filter((elem) => elem !== action.payload.id)
        state.selectedItems = filteredList
      }
    },
    setSelectedLineItems(state, action) {
      if (action.payload.type === 'add') {
        state.selectedItems = [...state.selectedItems, action.payload.ids]
      } else {
        const filteredList = state.selectedItems.filter((elem) => elem !== action.payload.ids)
        state.selectedItems = filteredList
      }
    },
    setDoFetchAssetList(state, action) {
      state.doFetchAssetList = action.payload
    },
    clearModelByMake(state, action) {
      state.getModelByMake.getModelByMake = []
    },
    getModelByMakeRequest(state, action) {
      state.getModelByMake.loading = true
    },
    getModelByMakeSuccess(state, action) {
      state.getModelByMake.getModelByMake = action.payload
      state.getModelByMake.loading = false
    },
    getModelByMakeFailure(state, action) {
      state.getModelByMake.error = action.payload
      state.getModelByMake.loading = false
    },
    dropAssetsRequest(state, action) {
      state.dropAssets.loading = true
    },
    dropAssetsSuccess(state, action) {
      state.dropAssets.dropAssets = action.payload
      state.dropAssets.loading = false
    },
    dropAssetsFailure(state, action) {
      state.dropAssets.error = action.payload
      state.dropAssets.loading = false
    },
    assetClassRequest(state, action) {
      state.assetClass.loading = true
    },
    assetClassSuccess(state, action) {
      state.assetClass.assetClass = action.payload
      state.assetClass.loading = false
    },
    assetClassFailure(state, action) {
      state.assetClass.error = action.payload
      state.assetClass.loading = false
    },
    getConditionsRequest(state, action) {
      state.getConditions.loading = true
    },
    getConditionsSuccess(state, action) {
      state.getConditions.getConditions = action.payload
      state.getConditions.loading = false
    },
    getConditionsFailure(state, action) {
      state.getConditions.error = action.payload
      state.getConditions.loading = false
    },
    postAssetClassRequest(state, action) {
      state.postAssetClass.loading = true
    },
    postAssetClassSuccess(state, action) {
      state.postAssetClass.postAssetClass = action.payload
      state.postAssetClass.loading = false
    },
    postAssetClassFailure(state, action) {
      state.postAssetClass.error = action.payload
      state.postAssetClass.loading = false
    },
    postRenameAreaRequest(state, action) {
      state.postRenameArea.loading = true
    },
    postRenameAreaSuccess(state, action) {
      state.postRenameArea.postRenameArea = action.payload
      state.postRenameArea.loading = false
    },
    postRenameAreaFailure(state, action) {
      state.postRenameArea.error = action.payload
      state.postRenameArea.loading = false
    },
    deleteInnerAssetRequest(state, action) {
      state.deleteInnerAsset.loading = true
    },
    deleteInnerAssetSuccess(state, action) {
      state.deleteInnerAsset.deleteInnerAsset = action.payload
      state.deleteInnerAsset.loading = false
    },
    deleteInnerAssetFailure(state, action) {
      state.deleteInnerAsset.error = action.payload
      state.deleteInnerAsset.loading = false
    },
    clearSelectedItems(state) {
      state.selectedItems = []
    },
  },
})

export const assetsListActions = assetsSlice.actions

export default assetsSlice.reducer
