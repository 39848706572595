/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-duplicate-imports */
import React, {useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import {Box, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography, Grid, FormControl, Select, MenuItem, Menu} from '@mui/material'
import {Typeahead} from 'react-bootstrap-typeahead'
import {Close} from '@mui/icons-material'
import {decode as base64Decode} from 'base-64'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {CustomTextBox} from '../../../../Inputs/index.js'
import {CustomButton, CustomTextArea} from '../../../../Inputs/index.js'
import {CustomAutoComplete} from '../../../../Inputs/index.js'
import {adminListActions} from '../../../../../redux/admin/admin.js'
import {getLocationsDataAction} from '../../../../../redux/home/HomeActions'
import {getMakeCapacityAction, getAssetDetailsAction} from '../../../../../redux/lines/LineActions'

import MyDiv from './insertNewLineDialog.style'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))


export default function InsertNewLineDialog(props) {
  console.log('🚀 ~ InsertNewLineDialog ~ props:', props)
  const CheckType = (value) => {
    let flag
    let digitRegex = /^[0-9]+$/
    let valid = digitRegex.test(value)
    if (valid) {
      flag = true
    } else {
      flag = false
    }
    return flag
  }
  const popup = () => (
    <MyDiv>
      <Box className="dialog_box">
        <Box className="dialog_header">
          <DialogTitle className="dialog_title">Insert New Line</DialogTitle>
          <IconButton className="icon" onClick={props.handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Grid container columnSpacing={{sm: 4, md: 4}}>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography className="label">Make</Typography>
              <Typeahead
                id="basic-typeahead"
                allowNew
                filterBy={(option, props) => {
                  return option?.make?.toLowerCase().startsWith(props?.text.toLowerCase())
                }}
                // clearButton
                labelKey={(option) => option?.make?.toString() || ''}
                options={props.makeList}
                placeholder="Search Make"
                selected={props.formData?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                onChange={(selected) => props.handleSelected(selected, 'make')}
                onInputChange={props.handleCustomTypeAheadForMakeForLine}
                onKeyDown={props.handleKeyDownForTypeAheadForLine}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="text_flex">
                <Typography>Text1</Typography>
                <Typography className="note">Max 500 characters</Typography>
              </Box>
              <CustomTextBox
                value={props?.formData?.line_name}
                onChange={props.handleChange}
                maxLength="500"
                name="line_name"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="text_flex">
                <Typography>Text2</Typography>
                <Typography className="note">Max 100 characters</Typography>
              </Box>
              <CustomTextBox
                value={props?.formData?.line_text2}
                onChange={props.handleChange}
                maxLength="100"
                name="line_text2"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography className="label">Asset#</Typography>
              <CustomTextBox
                autoFocus
                value={props.formData?.asset_no}
                onChange={props.handleChange}
                name="asset_no"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="d-flex add_btn">
                <Typography className="label">Asset Type</Typography>
              </Box>
              <Typeahead
                id="basic-typeahead"
                // allowNew
                // clearButton
                filterBy={(option, props) => {
                  return option?.asset_type
                    ?.toLowerCase()
                    .startsWith(props?.text.toLowerCase())
                }}
                labelKey={(option) => option?.asset_type?.toString() || ''}
                options={props.assetTypes}
                placeholder="Search Asset Types"
                selected={props.formData?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                // onChange={(selected) => props.handleSelected(selected, 'assetType')}
                onChange={(selected) => props.handleSelectedForLineAndAssetTypeForLine(selected)}
                // onKeyDown={props.handleKeyDownForTypeAheadForLine}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography className="label">Asset Class</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={props?.formData?.asset_class_id ?? ''}
                  onChange={props.handleChange}
                  name="asset_class_id"
                  MenuProps={{...Menu}}
                >
                  {props.assetClassList?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.asset_class_id}>
                        {item.name}
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography className="label">Year</Typography>
              <CustomTextBox
                maxLength={4}
                value={props.formData?.year}
                onChange={props.handleChange}
                name="year"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography className="label">Condition</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={props.formData?.condition}
                  // onChange={props.handleChange}
                  name="condition"
                  MenuProps={{...Menu}}
                >
                  {props.condition?.map((item, index) => {
                    return (
                      <StyledMenuItem key={item.id} value={item.name}>
                        {item.name}
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box className="input_box">
              <Typography className="label">Appraiser Notes</Typography>
              <CustomTextArea minRows={3} value={props.formData?.appraiser_note} name="appraiser_note" onChange={props.handleChange} />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography className="label">Cost ($)</Typography>
              <CustomTextBox
                type="number"
                value={props.formData?.cost ?? ''}
                onChange={props.handleChange}
                name="cost"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={{sm: 4, md: 4}}>
          {props?.formData?.type_id ?
            props.valueCodes?.map((value, index) => {
              let percentageValue = ((props.inputValues[value]) / props.inputValues?.cost) * 100
              let type = CheckType(props.inputValues[value])
              return (
                <Grid item md={3} key={index}>
                  <Box className="input_box">
                    <Typography className="label">{value} ($):</Typography>
                    {type ? (props.inputValues[value] && props.inputValues?.cost > 0 ? <Typography>{percentageValue.toFixed(2)}% of Cost</Typography> : '') : ''}

                    <CustomTextBox
                      onChange={props.handleChange}
                      name={value}
                      value={props.inputValues[value]}
                      onBlur={props.handleFocus}
                    />
                  </Box>
                </Grid>
              )
            })
            :
            props?.getPriceValues()
          }
        </Grid>

        <DialogActions>
          <Box className="d-flex btn-flex mb-20 mt-10">
            <CustomButton
              title="Insert"
              variant="contained"
              className="btn_theme"
              onClick={props.handleInsertNewLine}
              disabled={(props.formData?.line_name === undefined && props.formData?.asset_class_id === undefined) ? true : false}
            />
            <CustomButton
              title="Cancel"
              variant="contained"
              className="btn_theme"
              onClick={props.handleClose}
            />
          </Box>
        </DialogActions>
      </Box>
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openDialog"
      open={props.openDialog}
      TransitionComponent={Transition}
      // onClose={props.handleDialog}
      // fullWidth
      maxWidth={'lg'}
    >
      {popup('openDialog')}
    </Dialog>
  )
}
