/* eslint-disable camelcase */
import apiInstance from '../config/api/axios'

export const getLastAsset = async({getLastAsset, location_id, project_id_crm}) => {
  try {
    const queryParams = new URLSearchParams()
    if (getLastAsset) queryParams.append('getLastAsset', getLastAsset)
    if (location_id) queryParams.append('location_id', location_id)
    if (project_id_crm) queryParams.append('project_id_crm', project_id_crm)

    const {data} = await apiInstance.get(`location?${queryParams.toString()}`)
    return data
  } catch (error) {
    console.error('Error fetching last asset:', error)
    throw error
  }
}

export const fetchMakeCapacityList = async({project_id_crm, type}) => {
  try {
    const {data} = await apiInstance.get(`common?project_id_crm=${project_id_crm}&type=${type}`)
    return data
  } catch (error) {
    console.error('Error fetching make capacity list:', error)
    throw error
  }
}

export const fetchAreaList = async(location_id) => {
  try {
    const {data} = await apiInstance.get(`area-by-location?location_id=${location_id}`)
    return data.areaList
  } catch (error) {
    console.error('Error fetching area list:', error)
    throw error
  }
}

export const fetchLineByArea = async({area, location_id}) => {
  try {
    const {data} = await apiInstance.get(`line-by-area?area=${encodeURIComponent(area)}&location_id=${location_id}`)
    return data?.lineList
  } catch (error) {
    console.error('Error fetching line by area:', error)
    throw error
  }
}

export const getAssetDetailByLocation = async({id, location_id, project_id_crm}) => {
  try {
    const queryParams = new URLSearchParams()
    if (id) queryParams.append('id', id)
    if (location_id) queryParams.append('location_id', location_id)
    if (project_id_crm) queryParams.append('project_id_crm', project_id_crm)

    const {data} = await apiInstance.get(`asset-detail?${queryParams.toString()}`)
    return data
  } catch (error) {
    console.error('Error fetching asset detail by location:', error)
    throw error
  }
}
export const fetchModelListByMake = async() => {
  try {
    const response = await apiInstance.get('make-model-list')
    return response?.data
  } catch (error) {
    throw error
  }
}


export const postAsset = async(payload) => {
  try {
    const response = await apiInstance.post('asset-detail', payload)
    return response?.data
  } catch (error) {
    throw error
  }
}

export const getAssetList = async({id, location_id, project_id_crm}) => {
  try {
    const {data} = await apiInstance.get(
      `asset-detail?id=${id}&location_id=${location_id}&project_id_crm=${project_id_crm}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const getAssetInEdit = async(payload) => {
  try {
    const res = await apiInstance.post(
      'asset-list?action=getAssetInEdit',
      payload
    )
    return res?.data
  } catch (error) {
    throw error
  }
}

export const deleteCurrentAsset = async(payload) => {
  try {
    const response = await apiInstance.delete(`asset-detail/${payload}`)
    return response
  } catch (error) {
    throw error
  }
}

export const addNewLine = async(payload) => {
  try {
    const response = await apiInstance.post('line-detail', payload)
    return response
  } catch (error) {
    throw error
  }
}
