import React, {useEffect} from 'react'
import './App.css'
import {useHistory} from 'react-router-dom'
import lzString from 'lz-string'
import {useSelector, useDispatch} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {getModelByMakeAction, saveModelData} from '../../redux/assets/AssetsActions'
import {getAppraiserTrueAction} from '../../redux/admin/AdminActions'
import Routes from './Routes'

const App = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  const modelState = useSelector((state) => state.assets?.getModelByMake?.getModelByMake)

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'project_id_crm') {
        const userSession = localStorage.getItem('project_id_crm')
        if (userSession) {
          setTimeout(() => {
            window.location.href = '/'
          }, 3000) // ! timeout because we need to wait to be authenticated before we push the user onto a new page
        }
      }
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string
  useEffect(() => {
    dispatch(getAppraiserTrueAction({getAppraisersOnly: true, project_id_crm: decodedProjectId}))
  }, [dispatch])
  useEffect(() => {
    localStorage.removeItem('type_id')
    localStorage.removeItem('current_type_ref')
    localStorage.removeItem('typeIdForEdit')
    if (!localStorage.getItem('token')) {
      history.push('/auth')
    }
    const compressedModelData = localStorage.getItem('make_model')
    if (modelState.length === 0 && compressedModelData) {
      const decompressedModelData = JSON.parse(lzString.decompressFromUTF16(compressedModelData))
      dispatch(saveModelData(decompressedModelData))
    } else {
      dispatch(getModelByMakeAction())
    }
  }, [])

  return (
    <div className="App">
      <Routes />
    </div>
  )
}

export default App
